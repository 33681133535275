/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  updateUserDocument,
  userDocumentDelete,
} from "../../../services/client/addclientApi";
import { fetchUserDocument } from "../../../Redux/clientsSlice";
import { Field, Form, Formik } from "formik";
import { formatDate } from "../../Common/FormatDate";
import DoumentPdf from "../../Common/DocPdf/DoumentPdf";
import DeleteModal from "../../Common/DeleteModal";
import { IoCloseOutline } from "react-icons/io5";
import { ImFolderDownload } from "react-icons/im";
import DocumentConfirmModal from "../../Common/DocumentConfirmModal";
import { BsFilePdf } from "react-icons/bs";

export function getFileExtension(filePath) {
  return filePath.split(".").pop();
}

export function getFileName(filePath) {
  return filePath.substring(0, filePath.lastIndexOf("."));
}

const ProspectProfile = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [isDocId, setIsDocsId] = useState("");
  const [modalShow, setModalShow] = useState("");
  const [isDescription, setIsDescription] = useState("");
  const [isOpenAccordion, setOpenAccordion] = useState("");
  const [isDocumentsId, setIsDocumentsId] = useState("");
  const [isStatus, setIsStatus] = useState("");
  const [isActiveDocument, setActiveDocument] = useState({});
  const [confirmModalShow, setConfirmModalShow] = useState(false);
  const [isUserId, setIsUserId] = useState(false);
  const [activeUrl, setActiveUrl] = useState(null);
  const { customerData, userDocumentList } = useSelector(
    (state) => state?.clientsSlice
  );
  const singleUser = customerData?.customer;
  //  delete document
  const handleDelete = async () => {
    const body = { doc_id: isActiveDocument?._id };
    await userDocumentDelete(userDocumentList?._id, body);
    dispatch(fetchUserDocument(isOpenAccordion));
    setModalShow(false);
    setActiveDocument({});
  };

  const handleConfirmModal = (item, status) => {
    setConfirmModalShow(true);
    setIsUserId(id);
    setIsDocsId(item?._id);
    setIsStatus(status);
    if (status) {
      setIsDescription("Avez-vous confirmé le document ?");
    } else {
      setIsDescription("Êtes-vous rejeté le document ?");
    }
  };

  function getFileExtension(filePath) {
    return filePath.split(".").pop();
  }

  function getFileName(filePath) {
    return filePath.substring(0, filePath.lastIndexOf("."));
  }

  const handleDownload = async () => {
    try {
      console.log(activeUrl);
      const downloadUrl = `${process.env.REACT_APP_IMG_URL}${activeUrl}`;
      const response = await fetch(downloadUrl);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const blob = await response.blob();
      const link = document.createElement("a");
      const url = window.URL.createObjectURL(blob);
      link.href = url;
      link.download =
        `${getFileName(isActiveDocument?.name)}.${getFileExtension(
          activeUrl
        )}` || "document";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("There was an error downloading the file:", error);
    }
  };

  console.log("🚀 ~ ProspectProfile ~ userDocumentList:", userDocumentList);

  // initial open Accordion
  useEffect(() => {
    setOpenAccordion(singleUser?._id);
    setActiveDocument(userDocumentList?.documents?.[0]);
  }, [id, userDocumentList]);

  useEffect(() => {
    dispatch(fetchUserDocument(id));
  }, []);

  useEffect(() => {
    setActiveUrl("");
  }, [isActiveDocument]);

  const getSideBarPreviewItem = (url) => {
    return (
      <>
        {url?.includes("pdf") ? (
          <div
            style={{
              color: "#000",
              fontSize: "3rem",
            }}
          >
            <BsFilePdf />
          </div>
        ) : url ? (
          <img
            src={`${process.env.REACT_APP_IMG_URL}${url}`}
            alt={""}
            className="img-fluid"
            width={100}
            height={100}
          />
        ) : (
          <div className="text-center"></div>
        )}
      </>
    );
  };

  return (
    <>
      {
        <div className="row documents">
          <div className="col-3">
            {userDocumentList?.documents?.length > 0 &&
              userDocumentList?.documents?.map((item, i) => (
                <Formik
                  key={i}
                  initialValues={{
                    name: item?.name?.split(".")[0] || "",
                    fileType: item?.name?.split(".")[1] || "",
                  }}
                  enableReinitialize={true}
                  onSubmit={async (values) => {
                    const form = new FormData();
                    form.append("name", values?.name + "." + values?.fileType);
                    form.append("doc_id", item?._id);
                    await updateUserDocument(singleUser?._id, form);
                    setIsDocumentsId(" ");
                  }}
                >
                  {({ values }) => (
                    <Form onClick={() => setActiveDocument(item)}>
                      <div
                        style={{
                          "--border-colore":
                            item?.status === "pending"
                              ? "#FF9F43"
                              : item?.status === "accept"
                              ? "#28C76F"
                              : "red",
                        }}
                        className={`doc-status card cursor-pointer border-left-3 border-right-3 mb-2 ${
                          item?._id === isActiveDocument?._id && "doc-active"
                        }`}
                      >
                        <div className="row align-items-center px-3 py-2 ">
                          <div className="col-9">
                            {isDocumentsId === item?._id ? (
                              <div className="d-flex align-items-center">
                                <Field
                                  readOnly={isDocumentsId !== item?._id}
                                  name="name"
                                  className={`cursor-pointer flex-grow-0 ${
                                    isDocumentsId === item?._id
                                      ? "form-control mb-1 "
                                      : "hide-input"
                                  }`}
                                  placeholder=""
                                />
                                .<div>{values?.fileType}</div>
                              </div>
                            ) : (
                              <div>{values?.name + "." + values?.fileType}</div>
                            )}

                            <div className="d-flex fs-7 gap-2">
                              <span>
                                {item?.firstname} {item?.lastname}
                              </span>
                              <span>{formatDate(item?.uploadedAt)}</span>
                            </div>
                          </div>

                          <div className=" col-3">
                            <div className="d-flex flex-column gap-1  justify-content-center align-items-center">
                              {item?.status === "pending" ? (
                                <>
                                  <IoCloseOutline
                                    className="fs-5 "
                                    onClick={() => {
                                      handleConfirmModal(item, false);
                                    }}
                                  />
                                  <span
                                    className="tf-icons ti-xs ti ti-check d-block text-center"
                                    onClick={() => {
                                      handleConfirmModal(item, true);
                                    }}
                                  />
                                </>
                              ) : (
                                <>
                                  <div>
                                    {isDocumentsId === item?._id ? (
                                      <button
                                        type="submit"
                                        className="btn btn-primary waves-effect waves-light px-2 py-1"
                                      >
                                        Save
                                      </button>
                                    ) : (
                                      <span
                                        onClick={() =>
                                          setIsDocumentsId(item?._id)
                                        }
                                        className="tf-icons ti-xs ti ti-edit d-block mb-2"
                                      ></span>
                                    )}
                                    <span
                                      onClick={() => setModalShow(true)}
                                      className="tf-icons ti-xs ti ti-trash d-block text-center"
                                    ></span>
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              ))}
          </div>

          <div className="col-9">
            <div className="card">
              <div className="card-body position-relative">
                <div className="row">
                  <div className="col-2">
                    <div className="d-flex flex-column gap-2">
                      <div
                        className={`d-flex justify-content-center align-items-center sidebar-preview-item ${
                          activeUrl === isActiveDocument?.url ? `active` : ``
                        }`}
                        onClick={() => setActiveUrl(isActiveDocument?.url)}
                      >
                        {getSideBarPreviewItem(isActiveDocument?.url)}
                      </div>
                      <div
                        className={`d-flex justify-content-center align-items-center sidebar-preview-item ${
                          activeUrl === isActiveDocument?.backUrl
                            ? `active`
                            : ``
                        }`}
                        onClick={() => setActiveUrl(isActiveDocument?.backUrl)}
                      >
                        {getSideBarPreviewItem(isActiveDocument?.backUrl)}
                      </div>
                    </div>
                  </div>
                  {activeUrl && (
                    <div className="col-10">
                      <ImFolderDownload
                        onClick={handleDownload}
                        className="fs-4 text-black mb-2 cursor-pointer"
                        style={{
                          zIndex: 999,
                          inset: "20px",
                        }}
                      />
                      {activeUrl?.includes("pdf") ? (
                        <DoumentPdf
                          isActiveDocument={isActiveDocument}
                          setActiveDocument={setActiveDocument}
                          isOpenAccordion={isOpenAccordion}
                        />
                      ) : activeUrl ? (
                        <img
                          src={`${process.env.REACT_APP_IMG_URL}${activeUrl}`}
                          alt={isActiveDocument?.image}
                          className="img-fluid"
                        />
                      ) : (
                        <div className="text-center">No Data Found</div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          <DeleteModal
            title={"Supprimer ce document"}
            description={"Êtes-vous sûr de supprimer le document ?"}
            show={modalShow}
            deleteData={() => handleDelete(false)}
            onHide={() => setModalShow(false)}
          />

          <DocumentConfirmModal
            isUserId={isUserId}
            isDocId={isDocId}
            isStatus={isStatus}
            description={isDescription}
            show={confirmModalShow}
            onHide={() => setConfirmModalShow(false)}
          />
        </div>
      }
    </>
  );
};

export default ProspectProfile;
