import { FaChevronRight } from "react-icons/fa";
import { FiUsers } from "react-icons/fi";
import { IoMdCalendar } from "react-icons/io";
import { IoClose, IoTrashOutline } from "react-icons/io5";
import { LiaCarSideSolid } from "react-icons/lia";
import { PiHashStraightFill } from "react-icons/pi";

const carTypes = [
  "Coupé sport",
  "Berline",
  "SUV",
  "Éléctrique",
  "Hybride",
  "Cabriolet",
  "Hatchback",
  "4X4",
  "Break",
  "Roadster",
];

const filterOpts = {
  type: carTypes,
};

const FilterSidebar = ({
  setActiveFilter,
  activeFilter,
  setAppliedFilters,
  resetFilters,
  appliedFilters,
}) => {
  const renderFilterChips = () => {
    return (
      <div className="d-flex flex-wrap gap-3">
        {Object.entries(appliedFilters).map((r) => {
          return (
            <>
              {r[1].map((v) => (
                <div
                  className="filter-chip"
                  role="button"
                  onClick={() =>
                    setAppliedFilters({
                      ...appliedFilters,
                      [r[0]]: [...appliedFilters[r[0]].filter((f) => f !== v)],
                    })
                  }
                >
                  {r[0] === "category" ? `Catégorie 0${v}` : v}
                  <IoClose />
                </div>
              ))}
            </>
          );
        })}
      </div>
    );
  };

  return (
    <div
      style={{
        background: "#FFF",
        height: "100%",
        width: "300px",
        padding: "80px 15px",
      }}
      className="right-sub-bar"
    >
      <div className="">
        <label className="checkbox-div d-flex align-items-start gap-3">
          <input type="checkbox" className="new-checkbox" />
          <div>Afficher uniquement les ressources disponibles</div>
        </label>
      </div>
      <hr style={{ borderColor: "#EFEFEF" }} />
      <div
        className={
          activeFilter === "type"
            ? "active-filter-sidebar"
            : "inactive-filter-sidebar"
        }
      >
        <a
          className="filter-header"
          role="button"
          onClick={() => setActiveFilter(activeFilter === "type" ? "" : "type")}
        >
          <LiaCarSideSolid className="filter-ico" />
          <div className="filter-title">
            Type de voiture{" "}
            {appliedFilters?.type?.length > 0 && (
              <span>({appliedFilters?.type?.length})</span>
            )}
          </div>
          <FaChevronRight className="drop-arr" />
        </a>
        <div className="filt-items">
          {filterOpts[activeFilter]?.map((opt) => (
            <label className="checkbox-div d-flex align-items-start gap-3 ">
              <input
                type="checkbox"
                className="new-checkbox mt-1"
                checked={appliedFilters?.type?.includes(opt)}
                onChange={(e) =>
                  setAppliedFilters({
                    ...appliedFilters,
                    type: e.target.checked
                      ? [...appliedFilters.type, opt]
                      : [...appliedFilters.type.filter((f) => f !== opt)],
                  })
                }
              />{" "}
              {opt}
            </label>
          ))}
        </div>
      </div>
      <hr style={{ borderColor: "#EFEFEF" }} />
      <div
        className={
          activeFilter === "client"
            ? "active-filter-sidebar"
            : "inactive-filter-sidebar"
        }
      >
        <a
          className="filter-header"
          role="button"
          onClick={() =>
            setActiveFilter(activeFilter === "client" ? "" : "client")
          }
        >
          <FiUsers className="filter-ico" />
          <div className="filter-title">
            Type de Client{" "}
            {appliedFilters?.client?.length > 0 && (
              <span>({appliedFilters?.client?.length})</span>
            )}
          </div>
          <FaChevronRight className="drop-arr" />
        </a>
        <div className="filt-items">
          {["Particulier", "Entreprise"]?.map((opt) => (
            <label className="checkbox-div d-flex align-items-start gap-3">
              <input
                type="checkbox"
                className="new-checkbox mt-1"
                checked={appliedFilters?.client?.includes(opt)}
                onChange={(e) =>
                  setAppliedFilters({
                    ...appliedFilters,
                    client: e.target.checked
                      ? [...appliedFilters.client, opt]
                      : [...appliedFilters.client.filter((f) => f !== opt)],
                  })
                }
              />{" "}
              {`${opt}`}
            </label>
          ))}
        </div>
      </div>
      <hr style={{ borderColor: "#EFEFEF" }} />
      <div
        className={
          activeFilter === "category"
            ? "active-filter-sidebar"
            : "inactive-filter-sidebar"
        }
      >
        <a
          className="filter-header"
          role="button"
          onClick={() =>
            setActiveFilter(activeFilter === "category" ? "" : "category")
          }
        >
          <PiHashStraightFill className="filter-ico" />
          <div className="filter-title">
            Catégories{" "}
            {appliedFilters?.category?.length > 0 && (
              <span>({appliedFilters?.category?.length})</span>
            )}
          </div>
          <FaChevronRight className="drop-arr" />
        </a>
        <div className="filt-items">
          {[1, 2, 3, 4, 5, 6]?.map((opt) => (
            <label className="checkbox-div d-flex align-items-start gap-3">
              <input
                type="checkbox"
                className="new-checkbox mt-1"
                checked={appliedFilters?.category?.includes(opt)}
                onChange={(e) =>
                  setAppliedFilters({
                    ...appliedFilters,
                    category: e.target.checked
                      ? [...appliedFilters.category, opt]
                      : [...appliedFilters.category.filter((f) => f !== opt)],
                  })
                }
              />{" "}
              {`Catégorie 0${opt}`}
            </label>
          ))}
        </div>
      </div>
      <hr style={{ borderColor: "#EFEFEF" }} />
      <div
        className={
          activeFilter === "status"
            ? "active-filter-sidebar"
            : "inactive-filter-sidebar"
        }
      >
        <a
          className="filter-header"
          role="button"
          onClick={() =>
            setActiveFilter(activeFilter === "status" ? "" : "status")
          }
        >
          <IoMdCalendar className="filter-ico" />
          <div className="filter-title">
            État de la ressource{" "}
            {appliedFilters?.status?.length > 0 && (
              <span>({appliedFilters?.status?.length})</span>
            )}
          </div>
          <FaChevronRight className="drop-arr" />
        </a>
        <div className="filt-items">
          {[
            "Maintenance",
            "Confirmed",
            "Not Confirmed",
            "En cours",
            "Terminé",
            "Bloquée",
            "Disponible",
          ]?.map((opt) => (
            <label className="checkbox-div d-flex align-items-start gap-3">
              <input
                type="checkbox"
                className="new-checkbox mt-1"
                checked={appliedFilters?.status?.includes(opt)}
                onChange={(e) =>
                  setAppliedFilters({
                    ...appliedFilters,
                    status: e.target.checked
                      ? [...appliedFilters.status, opt]
                      : [...appliedFilters.status.filter((f) => f !== opt)],
                  })
                }
              />{" "}
              {opt}
            </label>
          ))}
        </div>
      </div>
      <hr style={{ borderColor: "#EFEFEF" }} />
      <div className="d-flex flex-wrap justify-content-around filter-header">
        {["LU", "BE", "FR"].map((opt) => (
          <div>
            <label className="checkbox-div d-flex flex-column align-items-center gap-2">
              <input
                type="checkbox"
                className="new-checkbox"
                checked={appliedFilters?.company?.includes(opt)}
                onChange={(e) =>
                  setAppliedFilters({
                    ...appliedFilters,
                    company: e.target.checked
                      ? [...appliedFilters.company, opt]
                      : [...appliedFilters.company.filter((f) => f !== opt)],
                  })
                }
              />
              <div>{opt}</div>
            </label>
          </div>
        ))}
      </div>
      <hr style={{ borderColor: "#EFEFEF" }} />
      <div className="clear-filter-opt" role="button" onClick={resetFilters}>
        <IoTrashOutline /> réinitialiser les filtres
      </div>
      <hr style={{ borderColor: "#EFEFEF" }} />
      <div>{renderFilterChips()}</div>
    </div>
  );
};

export default FilterSidebar;
