import { Autocomplete, Box, InputBase, Paper } from "@mui/material";
import { useState } from "react";
import { BsPlus, BsX, BsXLg } from "react-icons/bs";

const CountrySelection = ({
  schengenCountries,
  isEdit,
  formik,
  onChange,
  value,
  removeCountry,
  onAdd,
}) => {
  const [showInput, setShowInput] = useState(false);

  const toggleInput = () => setShowInput(!showInput);

  return (
    <>
      <div className="d-flex gap-1 flex-wrap">
        {formik.values.country?.map((c) => (
          <div
            className={`drive-values-item ${
              value === c.code ? `selected` : ``
            }`}
            role="button"
            onClick={() => onChange(c.code)}
          >
            {c.code}

            {!isEdit && (
              <BsX
                style={{ fontSize: "20px" }}
                role="button"
                onClick={(e) => {
                  e.stopPropagation();
                  formik.setFieldValue("country", [
                    ...formik.values.country.filter((r) => r.code !== c.code),
                  ]);
                }}
              />
            )}
          </div>
        ))}

        {!isEdit && (
          <div className="d-flex gap-1">
            {showInput && (
              <Autocomplete
                multiple
                id="vehicle-selection-filled"
                disabled={isEdit}
                options={schengenCountries} // Use grouped vehicles as options
                getOptionLabel={(option) => option?.code?.toString()}
                onChange={(e, newVal) => {
                  formik.setFieldValue("country", newVal);
                  onAdd(newVal[newVal.length - 1]?.code);
                  toggleInput();
                }}
                value={formik.values.country} // Use Formik's value for vehicle selection
                sx={{
                  "& .MuiAutocomplete-inputRoot": {
                    paddingRight: "0px !important", // Removes right-side padding
                  },
                  "& .MuiAutocomplete-popupIndicator": {
                    display: "none", // Hides the dropdown icon
                  },
                  "& .MuiChip-label": {
                    padding: 0,
                  },
                }}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => {
                    const { key } = getTagProps({ index });
                    return (
                      <Box
                        variant="outlined"
                        label={option.title}
                        key={key || option.title}
                        sx={{
                          color: "#000000",
                          borderRadius: "10px",
                          width: "100%",
                          display: "flex",
                          justifyContent: "space-between",
                          border: "none",
                          fontSize: 12,
                          textAlign: "left",
                          py: 0.5,
                          gap: 0.5,
                          flexWrap: "no-wrap",
                        }}
                      >
                        {option.title}
                      </Box>
                    );
                  })
                }
                clearIcon={false}
                popupIcon={null}
                renderInput={(params) => (
                  <Paper component="div" elevation={0}>
                    <InputBase
                      {...params.InputProps}
                      inputProps={params.inputProps}
                      style={{ flex: 1, p: 0 }}
                      placeholder="Pays disponible(s)"
                      className="drive-values-item"
                      size="small"
                    />
                  </Paper>
                )}
              />
            )}

            <div
              className="drive-values-item"
              role="button"
              onClick={toggleInput}
            >
              {showInput ? (
                <BsXLg style={{ fontSize: "15px" }} />
              ) : (
                <BsPlus style={{ fontSize: "15px" }} />
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default CountrySelection;
