/* eslint-disable react-hooks/exhaustive-deps */
import { useFormik } from "formik";
import { Dropdown } from "primereact/dropdown";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteReservation,
  getCustomer,
} from "../../services/client/addclientApi";
import {
  addReservation,
  deleteReservationById,
  getReservationById,
  updateResrvation,
} from "../../services/Calendar/reservation";
import { reservationList } from "../../Redux/calanderSlice";
import { fetchcompanyDropdown } from "../../Redux/commonSlice";
import { useNavigate } from "react-router-dom";
import { fetchCustomerData, getCustomerData } from "../../Redux/clientsSlice";
import { getSubscription } from "../../services/Company/addSubscriptionApi";
import { fetchSingleVehicleList } from "../../Redux/vehicleSlice";
import ConnectedCust from "./ConnectedCust";
import RealativeClient from "./RealativeClient";
import { Autocomplete } from "@react-google-maps/api";
import { schengenCountries } from "../Common/AddressAutocompleteInput";
import ReservationBg from "../../assets/img/reservation-bg.png";
import { IoTrashOutline } from "react-icons/io5";
import moment from "moment";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/flatpickr.min.css";
import { getCompanylist } from "../../services/Company/addCompanyApi";

const AddReservation = (props) => {
  const { show, onHide, res_id, setId, name, theme, finalNewResDates } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { companyDropdown } = useSelector((state) => state?.commonSlice);
  const { vehicleList } = useSelector((state) => state?.vehicleSlice);
  const [isLoding, setLoding] = useState(null);
  const [isCustomerList, setCustomerList] = useState(null);
  const [isVehicleDropdown, setVehicleDropdown] = useState([]);
  const [isSingleData, setSingleData] = useState([]);
  const currentDate = new Date().toISOString().split("T")[0]; // Get current date in YYYY-MM-DD format
  const [isStartDate, setStartDate] = useState("");
  const [isReturnDate, setIsReturnData] = useState("");
  const [isSingleCompany, setSingleCompany] = useState({});
  const [subscriptionValue, setSubscriptionValue] = useState("");
  const [isPrice, setPrice] = useState("");
  const [autocomplete, setAutocomplete] = useState("");
  const { customerData } = useSelector((state) => state?.clientsSlice);
  const { singlevehicleData } = useSelector((state) => state?.vehicleSlice);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [dateRange, setDateRange] = useState([]);
  const [turismoAddress, setTurismoAddress] = useState("Turismo HQ");

  const Status = [
    { label: "Non Confirmé", value: "Not Confirmed" },
    { label: "Confirmé", value: "Confirmed" },
    { label: "Maintenance", value: "Maintenance" },
  ];

  const ResvStates = [
    { label: "Check-out", value: "check-out" },
    { label: "Delivery-out", value: "delivery-out" },
    { label: "Delivery-in", value: "delivery-in" },
    { label: "Check-in", value: "check-in" },
    { label: "Archived ", value: "archived" },
  ];

  const allCompanylist = async () => {
    const list = await getCompanylist();
    const hqCompId =
      list?.data?.find((c) => c.name === "Turismo - LU")?.address || null;
    console.log(hqCompId);
    setTurismoAddress(
      hqCompId
        ? `${hqCompId?.number} ${hqCompId?.city} ${hqCompId?.street} ${hqCompId?.country} ${hqCompId?.postal}`
        : ``
    );
    // setCompanylist(list);
  };

  useEffect(() => {
    allCompanylist();
  }, []);

  // single Data start
  const singleData = async () => {
    if (res_id) {
      const data = await getReservationById(res_id);
      setSingleData(data?.data);
    } else {
      setSingleData(null);
    }
  };

  useEffect(() => {
    setSingleData("");
    singleData();
  }, [res_id]);
  // single Data end

  // Open the modal
  const handleCanceld = () => {
    setIsModalOpen(true);
  };

  // Close the modal
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  // Handle delete confirmation
  const handleConfirmDelete = async () => {
    await deleteReservationById(res_id);
    setIsModalOpen(false);
    onHide();
    setId(null);
    // alert("Reservation deleted!"); // Replace this with your actual delete logic
  };

  const generateTimeOptions = () => {
    const options = [];
    for (let hour = 0; hour < 24; hour++) {
      const formattedHour = hour.toString().padStart(2, "0");
      options.push(
        <option key={`${formattedHour}:00`} value={`${formattedHour}:00`}>
          {formattedHour}:00
        </option>
      );
      options.push(
        <option key={`${formattedHour}:30`} value={`${formattedHour}:30`}>
          {formattedHour}:30
        </option>
      );
    }
    return options;
  };

  useEffect(() => {
    if (finalNewResDates && !res_id) {
      console.log(finalNewResDates);
      setTimeout(() => {
        formik.setFieldValue("start_date", new Date(finalNewResDates.start));
        formik.setFieldValue("return_date", new Date(finalNewResDates.end));
        formik.setFieldValue("general_id", finalNewResDates.vehicle);
      }, 2000);
    }
  }, [finalNewResDates]);

  const formik = useFormik({
    initialValues: {
      company_id: isSingleCompany || "",
      general_id:
        {
          name: `${isSingleData?.general_id?.manufacturer?.name} ${isSingleData?.general_id?.model?.name} ${isSingleData?.general_id?.general_info?.license_plate}`,
          _id: isSingleData?.general_id?._id,
        } || "",
      customer_id:
        {
          name: `${isSingleData?.customer_id?.firstname} ${isSingleData?.customer_id?.lastname}`,
          _id: isSingleData?.customer_id?._id,
        } || "",
      status: isSingleData?.status || "",
      extra_km: isSingleData?.extra_km || "",
      start_date: isSingleData?.start_date
        ? new Date(isSingleData?.start_date)
        : "",
      dept_loc: isSingleData?.dept_loc || "",
      return_date: isSingleData?.return_date
        ? new Date(isSingleData?.return_date)
        : "",
      return_loc: isSingleData?.return_loc || "",
      start_loc: isSingleData?.start_loc || "",
      end_loc: isSingleData?.end_loc || "",
      type_voiture: isSingleData?.vahical_type || "",
      return_time: isSingleData?.return_time || "",
      dept_time: isSingleData?.dept_time || "",
    },

    onSubmit: async (values, { resetForm }) => {
      const body = { ...values };
      console.log(body);
      body.company_id = values?.company_id?._id;
      body.general_id = values?.general_id?._id;
      body.vahical_type = values?.type_voiture;
      if (
        values?.status === "Not Confirmed" ||
        values?.status === "Confirmed"
      ) {
        body.customer_id = values?.customer_id?._id
          ? values?.customer_id?._id
          : "";
      } else {
        delete body.customer_id;
      }
      setLoding(true);
      if (isSingleData?._id) {
        await updateResrvation(isSingleData?._id, body);
        onHide();
        setId(null);
      } else {
        const data = await addReservation(body);
        if (data) {
          onHide();
          resetForm();
        }
      }
      dispatch(reservationList());
      setLoding(false);
    },

    validateOnChange: false,
    enableReinitialize: true,
    validate: (values) => {
      console.log(values);
      const errors = {};
      if (!values.company_id?._id?.toString().trim()) {
        errors.company_id = "This field is required.";
      }
      // if (values.start_date?.toString().trim() < currentDate) {
      //   errors.start_date = "Invalide date.";
      // }
      console.log(values, "values");
      if (values.start_date && values.return_date) {
        if (
          new Date(values.return_date).getTime() <
          new Date(values.start_date).getTime()
        ) {
          errors.return_date = "Invalid date.";
        }
      } else {
        errors.return_date = "Required.";
        errors.start_date = "Required.";
      }
      return errors;
    },
  });

  console.log(formik.values.start_date);
  console.log(formik.values.return_date);

  const deleteData = async () => {
    const data = await deleteReservation(isSingleData?._id);
    if (data) {
      onHide();
      dispatch(reservationList());
    }
  };

  const customerDropdown = async () => {
    try {
      const dropdownData = await getCustomer();
      // filter base on rol
      const filterData = await dropdownData?.data?.filter((item) =>
        item.role?.includes("MAIN")
      );

      // client dropdown vlaues
      const formattedOptions = filterData?.map((customer) => ({
        name: `${customer?.firstname} ${customer?.lastname}`,
        _id: customer?._id,
      }));
      setCustomerList(formattedOptions);
    } catch (error) {
      console.error("Error fetching customer data:", error);
    }
  };

  useEffect(() => {
    setSingleCompany(isSingleData?.company_id);
    setStartDate(
      isSingleData?.start_date ? new Date(isSingleData?.start_date) : ""
    );
    setIsReturnData(
      isSingleData?.return_date ? new Date(isSingleData?.return_date) : ""
    );
  }, [vehicleList, isSingleData, res_id]);

  useEffect(() => {
    setPrice("");
    customerDropdown();
    dispatch(fetchcompanyDropdown());
  }, []);

  const handleCancel = () => {
    onHide(); // Close the Offcanvas
    formik.resetForm(); // Reset the Formik form
  };

  const handleNavigate = (role, id) => {
    if (role?.includes?.("CLIENT")) {
      navigate(`/client/profile/${id}`);
    } else {
      navigate(`/driver/profile/${id}`);
    }
  };

  useEffect(() => {
    const data = vehicleList
      ?.filter(
        (vehicle) => vehicle.active === "Active" && vehicle.status === "A louer"
      )
      .map((item) => ({
        name: `${item.manufacturer} ${item.model} ${item.license_plate}`,
        _id: item?._id,
      }));
    setVehicleDropdown(data);
  }, []);

  // subscriptionDropdownValue start
  const subscriptionDropdownValue = async (id) => {
    const subscriptionValue = await getSubscription(id);

    if (subscriptionValue !== undefined) {
      setSubscriptionValue(subscriptionValue?.data);
    } else {
      setSubscriptionValue([]);
    }
  };
  // subscriptionDropdownValue end

  // single vehile data
  const singleCarData = vehicleList?.filter(
    (item) => item?._id === formik?.values?.general_id?._id
  );

  useEffect(() => {
    if (singleCarData?.[0]?.general_id)
      dispatch(fetchSingleVehicleList(singleCarData?.[0]?.general_id));
  }, [formik?.values?.general_id?._id]);

  useEffect(() => {
    if (customerData?.customer?.sub_id?._id) {
      subscriptionDropdownValue(customerData?.customer?.sub_id?._id);
    }
  }, [customerData]);

  useEffect(() => {
    dispatch(fetchCustomerData(formik?.values?.customer_id?._id));

    const backdrop = document.getElementsByClassName(`c-offcanvas-backdrop`);
    if (backdrop) {
      backdrop[0].classList.add("hide");
      backdrop[0].classList.remove("show");
    }
  }, [formik?.values?.customer_id]);

  useEffect(() => {
    if (
      formik?.values?.status === "Maintenance" ||
      formik?.values?.status === "Repair" ||
      formik?.values?.status === "Transit"
    )
      dispatch(getCustomerData({}));
  }, [formik?.values?.status]);

  useEffect(() => {
    setPrice("");
    const categoryMap = {
      1: "category1",
      2: "category2",
      3: "category3",
      4: "category4",
      5: "category5",
      6: "category6",
    };
    const categoryKey =
      categoryMap[singlevehicleData?.General?.vehicle_category?.name];
    const price = subscriptionValue?.subscription?.bail[categoryKey];
    setPrice(price);
  }, [singlevehicleData, customerData, subscriptionValue]);

  //  google setAutocomplete start
  const handleLoad = (autocompleteInstance) => {
    setAutocomplete(autocompleteInstance);
  };

  const handlePlaceChanged = (name) => {
    if (autocomplete) {
      const place = autocomplete.getPlace();
      let fullAddress = "";

      if (place) {
        const addressComponents = place.address_components;

        if (addressComponents) {
          // Filter and map address components while excluding street_number
          const uniqueComponents = Array.from(
            new Set(
              addressComponents
                .filter(
                  (component) => !component.types.includes("street_number")
                )
                .map((component) => component.long_name)
            )
          );

          // Form the address by joining the components
          const address = uniqueComponents.join(", ");

          // Include the place name if it's not already part of the address
          fullAddress = address.includes(place.name)
            ? address
            : `${place.name}, ${address}`;

          // Set the form field value using Formik
          formik.setFieldValue(name, fullAddress);
        } else {
          console.error("No address components found in the place object.");
        }
      } else {
        console.error("Place is undefined or invalid.");
      }
    } else {
      console.error("Autocomplete instance is not initialized.");
    }
  };
  // google setAutocomplete end

  const a = Status?.find((item) => item.value === isSingleData?.status);
  return (
    <>
      {/* {show && <div className="fade c-offcanvas-backdrop hide"></div>} */}
      <div
        className={`c-offcanvas offcanvas-end ${
          show ? "c-show transparent-bg" : ""
        }`}
        tabIndex={-1}
        id="offcanvasEnd"
        aria-labelledby="offcanvasEndLabel"
        aria-modal="true"
        role="dialog"
        style={{ width: "550px" }}
      >
        <div
          className="add-reservation-content"
          style={{
            ...(theme === "light"
              ? {
                  // backgroundImage: theme === "light" && `url(${ReservationBg})`,
                  background: "#f5f7f9",
                }
              : {}),
            ...(theme === "dark"
              ? { background: theme === "dark" ? "#4b506f" : "" }
              : {}),
            borderRadius: "25px",
          }}
        >
          <div className="c-offcanvas-header">
            <h5 id="offcanvasEndLabel" className="offcanvas-title">
              {name}
            </h5>
            {/* <button
              onClick={() => onHide()}
              type="button"
              className="btn-close text-reset"
              aria-label="Close"
            /> */}
          </div>
          <div className="">
            <form
              className="add-new-user pt-0"
              id="addNewUserForm"
              onSubmit={formik.handleSubmit}
            >
              {/* relavtive driver start  */}
              {/* <ConnectedCust
                isSingleData={isSingleData}
                customerData={customerData}
                handleNavigate={handleNavigate}
                isPrice={isPrice}
              /> */}
              {/* relavtive client  */}
              {customerData?.relatedCustomer && (
                <RealativeClient
                  clientData={customerData?.relatedCustomer}
                  handleNavigate={handleNavigate}
                  isPrice={isPrice}
                  customerData={customerData}
                  isSingleData={isSingleData}
                />
              )}
              {/* relavtive driver end */}
              <div className="mb-3">
                <label className="form-label" htmlFor="add-user-email">
                  Société
                </label>
                <Dropdown
                  options={companyDropdown}
                  placeholder="Société"
                  className={`w-full ${
                    formik.errors.company_id ? "dropdown-invalid" : ""
                  }`}
                  name="company_id"
                  optionLabel="name"
                  value={formik.values.company_id}
                  onChange={formik.handleChange}
                />
                {formik.errors.company_id && (
                  <div className="invalid-feedback">
                    {formik.errors.company_id}
                  </div>
                )}
              </div>
              <div className="mb-3">
                <label className="form-label" htmlFor="add-user-email">
                  Informations de réservation
                </label>
                <Dropdown
                  options={Status}
                  placeholder="Status"
                  className={`w-full mb-1 ${
                    formik.errors?.firstName ? "dropdown-invalid" : ""
                  }`}
                  name="status"
                  value={formik.values.status}
                  onChange={formik.handleChange}
                />
                <Dropdown
                  filter
                  options={isVehicleDropdown}
                  placeholder="Véhicule"
                  className="w-full mb-1"
                  optionLabel="name"
                  name="general_id"
                  value={formik.values.general_id}
                  onChange={formik.handleChange}
                />
                {formik.errors.general_id && (
                  <div className="invalid-feedback">
                    {formik.errors.general_id}
                  </div>
                )}

                {formik.values?.status === "Maintenance" ||
                formik.values?.status === "Repair" ||
                formik.values?.status === "Transit" ? null : (
                  <>
                    <div className="mb-3">
                      <Dropdown
                        filter
                        options={isCustomerList}
                        placeholder="Client"
                        className="w-full"
                        optionLabel="name"
                        name="customer_id"
                        value={formik.values.customer_id}
                        onChange={formik.handleChange}
                      />
                    </div>
                  </>
                )}
              </div>
              <div className="mb-3">
                <label className="form-label" htmlFor="add-user-contact">
                  Date de réservation
                </label>
                <Flatpickr
                  options={{
                    mode: "range",
                    inline: true,
                    dateFormat: "d-m-Y",
                  }}
                  className="small-datepicker" // You can add a custom class for additional styling
                  value={[formik.values.start_date, formik.values.return_date]}
                  onChange={(selectedDates) => {
                    // setDateRange(selectedDates);
                    formik.setFieldValue("start_date", selectedDates[0]);
                    formik.setFieldValue("return_date", selectedDates[1]);
                    // console.log("Selected range:", selectedDates);
                  }}
                />
                {/* <input
                  type="date"
                  id="add-user-contact"
                  className={`form-control phone-mask ${
                    formik.errors.start_date ? "is-invalid-input" : ""
                  }`}
                  placeholder="Start date"
                  name="start_date"
                  value={formik.values.start_date}
                  onChange={formik.handleChange}
                /> */}
                {formik.errors.start_date && (
                  <span className="invalid-feedback">
                    {formik.errors.start_date}
                  </span>
                )}

                {/* <input
                  type="date"
                  id="add-user-contact"
                  className={`form-control mt-1 phone-mask ${
                    formik.errors.return_date ? "is-invalid-input" : ""
                  }`}
                  placeholder="Return date"
                  name="return_date"
                  value={formik.values.return_date}
                  onChange={formik.handleChange}
                /> */}
                {formik.errors.return_date && (
                  <span className="invalid-feedback">
                    {formik.errors.return_date}
                  </span>
                )}
              </div>
              <div>
                <label className="form-label">Livraison / Récuperation</label>
              </div>
              <div className="mb-3">
                <label className="custom-checkbox-label d-flex align-items-start gap-1">
                  <input
                    type="checkbox"
                    id="turismo-hq"
                    className="new-checkbox mt-1"
                    checked={formik.values.dept_loc === turismoAddress}
                    onClick={({ target }) => {
                      if (target.checked) {
                        formik.setFieldValue("dept_loc", turismoAddress);
                      } else {
                        formik.setFieldValue("dept_loc", "");
                      }
                    }}
                  />
                  Turismo HQ
                </label>
                <div className="d-flex gap-1">
                  <Autocomplete
                    onLoad={handleLoad}
                    onPlaceChanged={() => handlePlaceChanged("dept_loc")}
                    options={{
                      componentRestrictions: { country: schengenCountries },
                    }}
                  >
                    <input
                      type="text"
                      className="form-control mt-0"
                      placeholder="Adresse de livraison"
                      name="dept_loc"
                      autoComplete="off"
                      onChange={formik.handleChange}
                      Value={formik.values.dept_loc}
                      style={{ width: "100%" }}
                    />
                  </Autocomplete>
                  {/* Add Heure dropdown beside Lieux de Départ */}
                  {/* <input
                    className="form-control ms-2"
                    name="dept_time"
                    onChange={formik.handleChange}
                    value={formik.values.dept_time}
                    style={{ width: "100px" }}
                    placeholder="Heure"
                  /> */}

                  <input
                    className="form-control ms-2"
                    name="dept_time"
                    onChange={formik.handleChange}
                    onBlur={(e) => {
                      const inputTime = e.target.value;

                      // Match valid time (HH:MM or HHMM)
                      const timeMatch = inputTime.match(
                        /^(\d{1,2}):?(\d{2})?$/
                      );

                      if (timeMatch) {
                        let [_, hours, minutes] = timeMatch;
                        hours = parseInt(hours, 10);
                        minutes = minutes ? parseInt(minutes, 10) : 0;

                        if (hours < 24 && minutes < 60) {
                          // Reformat to 24h format
                          const formattedTime = `${hours
                            .toString()
                            .padStart(2, "0")}:${minutes
                            .toString()
                            .padStart(2, "0")}`;
                          formik.setFieldValue("dept_time", formattedTime);
                          return;
                        }
                      }

                      // If invalid input, reset or handle error
                      formik.setFieldValue("dept_time", "");
                      alert(
                        "Please enter a valid time in 24-hour format (e.g., 13:45 or 1345)"
                      );
                    }}
                    value={formik.values.dept_time}
                    style={{ width: "100px" }}
                    placeholder="Heure"
                  />
                </div>
              </div>
              <div className="mb-3">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    gap: "16px",
                  }}
                >
                  {/* First Checkbox and Label */}
                  {/* <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "8px",
                    }}
                  > */}
                  <label className="custom-checkbox-label d-flex align-items-start gap-1">
                    <input
                      type="checkbox"
                      id="add-user-contact"
                      className="new-checkbox mt-1"
                      checked={
                        formik.values.return_loc === formik.values.dept_loc &&
                        !!formik.values.return_loc
                      }
                      onClick={({ target }) => {
                        if (target.checked) {
                          formik.setFieldValue(
                            "return_loc",
                            formik.values.dept_loc
                          );
                        } else {
                          formik.setFieldValue("return_loc", "");
                        }
                      }}
                    />
                    Addresse identique
                  </label>
                  {/* </div> */}

                  {/* Second Checkbox and Label */}
                  {/* <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "8px",
                    }}
                  > */}
                  <label className="custom-checkbox-label d-flex align-items-start gap-1">
                    <input
                      type="checkbox"
                      id="turismo-hq"
                      className="new-checkbox mt-1"
                      checked={formik.values.return_loc === turismoAddress}
                      onClick={({ target }) => {
                        if (target.checked) {
                          formik.setFieldValue("return_loc", turismoAddress);
                        } else {
                          formik.setFieldValue("return_loc", "");
                        }
                      }}
                    />
                    Turismo HQ
                  </label>
                  {/* </div> */}
                </div>

                <div className="d-flex gap-1">
                  <Autocomplete
                    onLoad={handleLoad}
                    onPlaceChanged={() => handlePlaceChanged("return_loc")}
                    options={{
                      componentRestrictions: { country: schengenCountries },
                    }}
                  >
                    <input
                      min={currentDate}
                      type="text"
                      className="form-control mt-0"
                      placeholder="Adresse de Récupération"
                      name="return_loc"
                      autoComplete="off"
                      onChange={formik.handleChange}
                      Value={formik.values.return_loc}
                      style={{ width: "100%" }}
                    />
                  </Autocomplete>
                  {/* Add Heure dropdown beside Lieux de Retour */}

                  <input
                    className="form-control ms-2"
                    name="return_time"
                    onChange={formik.handleChange}
                    onBlur={(e) => {
                      const inputTime = e.target.value;

                      // Match valid time (HH:MM or HHMM)
                      const timeMatch = inputTime.match(
                        /^(\d{1,2}):?(\d{2})?$/
                      );

                      if (timeMatch) {
                        let [_, hours, minutes] = timeMatch;
                        hours = parseInt(hours, 10);
                        minutes = minutes ? parseInt(minutes, 10) : 0;

                        if (hours < 24 && minutes < 60) {
                          // Reformat to 24h format
                          const formattedTime = `${hours
                            .toString()
                            .padStart(2, "0")}:${minutes
                            .toString()
                            .padStart(2, "0")}`;
                          formik.setFieldValue("return_time", formattedTime);
                          return;
                        }
                      }

                      // If invalid input, reset or handle error
                      formik.setFieldValue("return_time", "");
                      alert(
                        "Please enter a valid time in 24-hour format (e.g., 13:45 or 1345)"
                      );
                    }}
                    value={formik.values.return_time}
                    style={{ width: "100px" }}
                    placeholder="Heure"
                  />
                </div>
              </div>
              <div className="mb-4">
                <button
                  type="submit"
                  className="btn btn-primary me-sm-3 me-1 data-submit "
                >
                  {isLoding && (
                    <span
                      className="spinner-border text-white me-2"
                      role="status"
                    ></span>
                  )}
                  {isSingleData ? "mise à jour" : "Enregistrer réservation"}
                </button>

                {!res_id && (
                  <button
                    type="reset"
                    className="btn btn-label-secondary me-sm-3 me-1 mt-3"
                    onClick={handleCancel}
                  >
                    <IoTrashOutline />
                    Annuler réservation
                  </button>
                )}
              </div>
              {/* <button
                type="reset"
                className="btn btn-label-secondary me-sm-3 me-1"
                onClick={handleCancel}
              >
                <IoTrashOutline />
                Annuler réservation
              </button> */}

              {/* <div className="calendar-container"> */}
              {/* Delete Reservation Button */}
              {res_id && (
                <button
                  type="button"
                  className="btn btn-label-secondary me-sm-3 me-1"
                  onClick={handleCanceld}
                >
                  <IoTrashOutline />
                  Annuler réservation
                </button>
              )}
              {/* </div> */}
            </form>
          </div>
        </div>
      </div>

      {show && (
        <div
          className={`c-offcanvas-backdrop fade show ${show ? "c-width" : ""}`}
          onClick={() => onHide()}
        ></div>
      )}

      {/* Full-Screen Modal */}
      {isModalOpen && (
        <div className="modal-overlay">
          <div className="modal-center-content">
            <h2>Suppression de réservation</h2>
            <p>
              Vous êtes sur le point de supprimer la réservation de{" "}
              <b>NOM PRÉNOM</b> à la <b>DATE</b> du{" "}
              {moment(isSingleData?.start_date).format("DD/MM/YYYY")}
            </p>
            <div className="modal-actions">
              <button className="btn-primary" onClick={handleConfirmDelete}>
                Supprimer
              </button>
              <button className="btn-secondary" onClick={handleCloseModal}>
                Abandonner
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AddReservation;

const CustomReservationPicker = () => {
  const [selectedDate, setSelectedDate] = useState("");
  const [currentDate, setCurrentDate] = useState(new Date());
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);

  const months = [
    "Nov",
    "Dec",
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "Mai",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
  ];

  const handleDateSelect = (day) => {
    const month = currentDate.getMonth() + 1; // Months are 0-indexed
    const year = currentDate.getFullYear();
    setSelectedDate(
      `${day.toString().padStart(2, "0")}/${month
        .toString()
        .padStart(2, "0")}/${year}`
    );
    setIsCalendarOpen(false);
  };

  const renderCalendarDays = () => {
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth();

    const firstDay = new Date(year, month, 1).getDay();
    const daysInMonth = new Date(year, month + 1, 0).getDate();

    const daysArray = [];
    for (let i = 0; i < (firstDay === 0 ? 6 : firstDay - 1); i++) {
      daysArray.push(
        <span
          key={`blank-${i}`}
          className="custom-reservation-blank-day"
        ></span>
      );
    }

    for (let i = 1; i <= daysInMonth; i++) {
      daysArray.push(
        <span
          key={`day-${i}`}
          className={`custom-reservation-day ${
            i === new Date().getDate() &&
            month === new Date().getMonth() &&
            year === new Date().getFullYear()
              ? "custom-reservation-selected"
              : ""
          }`}
          onClick={() => handleDateSelect(i)}
        >
          {i}
        </span>
      );
    }

    return daysArray;
  };

  const toggleCalendar = () => setIsCalendarOpen(!isCalendarOpen);

  const clearDate = () => {
    setSelectedDate("");
    setIsCalendarOpen(false);
  };

  const handleMonthSelect = (index) => {
    const newDate = new Date(currentDate.setMonth(index));
    setCurrentDate(newDate);
  };

  return (
    <div className="custom-reservation-picker">
      <div className="custom-reservation-input-container">
        <input
          type="text"
          className="custom-reservation-input"
          value={selectedDate}
          onClick={toggleCalendar}
          placeholder="DD/MM/YYYY - .../.../..."
          readOnly
        />
        <button className="custom-reservation-clear-btn" onClick={clearDate}>
          ×
        </button>
      </div>
      {isCalendarOpen && (
        <div className="custom-reservation-calendar">
          <div className="custom-reservation-calendar-body">
            <div className="custom-reservation-days">
              <div className="custom-reservation-days-header">
                <span>Dim</span>
                <span>Lun</span>
                <span>Mar</span>
                <span>Mer</span>
                <span>Jeu</span>
                <span>Ven</span>
                <span>Sam</span>
              </div>
              <div className="custom-reservation-days-grid">
                {renderCalendarDays()}
              </div>
            </div>
            <div className="custom-reservation-months">
              {months.map((month, index) => (
                <div
                  key={month}
                  className={`custom-reservation-month ${
                    index === currentDate.getMonth()
                      ? "custom-reservation-current-month"
                      : ""
                  }`}
                  onClick={() => handleMonthSelect(index)}
                >
                  {month}
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
