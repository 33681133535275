import React, { useEffect, useState } from "react";
import fullLogo from "../assets/img/icons/logo/Turismo-new.png";
import halfLogo from "../assets/img/icons/logo/single-new.svg";
import fullLogoDark from "../assets/img/icons/logo/LOGO BLANC copie.png";
import halfLogoDark from "../assets/img/icons/logo/half-logo-dark.svg";
import { Link, NavLink, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getLoggedInUserPermissions } from "../Redux/userSlice";

const sidebarLinks = [
  {
    section: "OPERATIONEL",
    links: [
      {
        title: "Calendrier",
        path: "calendar",
        icon: <i className="menu-icon tf-icons ti ti-calendar" />,
        key: "CALENDRIER",
      },
      {
        title: "Abonnés",
        path: "driver",
        icon: <i className="menu-icon tf-icons ti ti-users" />,
        key: "ABONNES",
      },
      {
        title: "Scrum",
        path: "scrum",
        icon: <i className="menu-icon tf-icons ti ti-layout-list" />,
        key: "SCRUM",
      },
    ],
  },
  {
    section: "Flotte",
    links: [
      {
        title: "Flotte",
        path: "fleet",
        icon: <i className="menu-icon tf-icons ti ti-car" />,
        key: "FLOTTE",
      },
      {
        title: "Checks",
        path: "checks/checkList",
        icon: <i className="menu-icon tf-icons ti ti-checkbox" />,
        key: "CHECKS",
      },
      {
        title: "Livraisons",
        path: "deliveries/delivery-out",
        icon: <i className="menu-icon tf-icons ti ti-repeat" />,
        key: "LIVRAISONS",
      },
    ],
  },
  {
    section: "FINANCIER",
    links: [
      {
        title: "Clients",
        path: "client",
        icon: <i className="menu-icon tf-icons ti ti-user" />,
        key: "CLIENTS",
      },
      {
        title: "Factures",
        path: "invoice",
        icon: <i className="menu-icon tf-icons ti ti-file-euro" />,
        key: "FACTURES",
      },
      {
        title: "Analyses",
        path: "analytics",
        icon: <i className="menu-icon tf-icons ti ti-chart-bar" />,
        key: "ANALYSES",
      },
    ],
  },
  {
    section: "MARKETING",
    links: [
      {
        title: "Prospect",
        path: "prospect",
        icon: <i className="menu-icon tf-icons ti ti-user" />,
        key: "PROSPECT",
      },
      {
        title: "Newsletter",
        path: "newsletter",
        icon: <i className="menu-icon tf-icons ti ti-mail" />,
        key: "NEWSLETTER",
      },
      {
        title: "Templates",
        path: "templates",
        icon: <i className="menu-icon tf-icons ti ti-file-text" />,
        key: "TEMPLATES",
      },
    ],
  },
];

const isDarkMode = document.body.classList.contains("dark-mode");

const Sidebar = ({
  iscollapsed,
  setHover,
  setToggle,
  handleCollapsed,
  isHover,
}) => {
  const [showAllOpts, setShowAllOpts] = useState(false);
  const dispatch = useDispatch();
  const [grantedLinks, setGrantedLinks] = useState([]);
  const { permissions } = useSelector((state) => state.userSlice);
  const [closeHover, setCloseHover] = useState(true);

  console.log("permissions", permissions);
  const handleToggleHover = () => {
    handleCollapsed();
    setCloseHover(!closeHover);
    setHover(false);
  };
  const { pathname } = useLocation();

  useEffect(() => {
    dispatch(getLoggedInUserPermissions());
  }, [pathname]);

  useEffect(() => {
    if (permissions?.length) {
      setGrantedLinks(() => {
        const finalLinks = [];

        sidebarLinks.forEach((l) => {
          const filteredLinks = l?.links?.filter((r) =>
            permissions?.includes(r.key)
          );
          if (filteredLinks.length) {
            finalLinks.push({
              section: l.section,
              links: filteredLinks,
            });
          }
        });

        return finalLinks;
      });
    } else {
      setGrantedLinks(sidebarLinks);
    }
  }, [permissions]);

  const THEME_KEY = "app-theme"; // Key for local storage

  // Get theme from localStorage or fallback to system theme
  const getInitialTheme = () => {
    const savedTheme = localStorage.getItem(THEME_KEY);
    if (savedTheme) return savedTheme;
    return window.matchMedia("(prefers-color-scheme: dark)").matches
      ? "dark"
      : "light";
  };

  const [theme, setTheme] = React.useState(getInitialTheme);

  // Function to update the theme
  const updateTheme = (newTheme) => {
    setTheme(newTheme);
    localStorage.setItem(THEME_KEY, newTheme);
    const event = new Event("localStorageUpdate");
    window.dispatchEvent(event);

    // Update the body class based on the theme
    document.body.classList.remove("light", "dark");
    if (newTheme !== "system") {
      document.body.classList.add(newTheme);
    } else {
      document.body.classList.add(
        window.matchMedia("(prefers-color-scheme: dark)").matches
          ? "dark"
          : "light"
      );
    }
  };

  // Effect to listen for system theme changes if "system" is selected
  useEffect(() => {
    const handleSystemThemeChange = (e) => {
      if (localStorage.getItem(THEME_KEY) === "system") {
        updateTheme("system");
      }
    };

    const mediaQuery = window.matchMedia("(prefers-color-scheme: dark)");
    mediaQuery.addEventListener("change", handleSystemThemeChange);

    return () =>
      mediaQuery.removeEventListener("change", handleSystemThemeChange);
  }, []);

  // Initialize the theme on mount
  useEffect(() => {
    updateTheme(theme);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fullBottomSection = () => (
    <>
      <div
        className={`theme-handler ${showAllOpts ? "expanded" : ""}`}
        onMouseEnter={() => setShowAllOpts(true)}
        onMouseLeave={() => setShowAllOpts(false)}
      >
        <li
          className={`theme-icon ${theme === "dark" ? "theme-selected" : ""}`}
          onClick={() => updateTheme("dark")}
          style={{
            display: showAllOpts || theme === "dark" ? "block" : "none",
          }}
        >
          <i className="tf-icons ti ti-moon" />
        </li>
        <li
          className={`theme-icon ${theme === "light" ? "theme-selected" : ""}`}
          onClick={() => updateTheme("light")}
          style={{
            display: showAllOpts || theme === "light" ? "block" : "none",
          }}
        >
          <i className="tf-icons ti ti-sun" />
        </li>
        <li
          className={`theme-icon ${theme === "system" ? "theme-selected" : ""}`}
          onClick={() => updateTheme("system")}
          style={{
            display: showAllOpts || theme === "system" ? "block" : "none",
          }}
        >
          <i class="tf-icons ti ti-device-desktop"></i>
        </li>
      </div>
      <li className="menu-item">
        <Link to={"settings/company"}>
          <i className="menu-icon tf-icons ti ti-settings" />
          <div></div>
        </Link>
      </li>
      <li className="menu-item">
        <Link to={"team/profile"}>
          <i className="menu-icon tf-icons ti ti-user-circle" />
          <div></div>
        </Link>
      </li>
      <li className="menu-item">
        <Link onClick={() => localStorage.clear()}>
          <i className="menu-icon tf-icons ti ti-logout" />
          <div></div>
        </Link>
      </li>
    </>
  );

  const halfBottomSection = () => (
    <li className="menu-item">
      <Link to={"team/profile"}>
        <i className="menu-icon tf-icons ti ti-user-circle" />
        <div></div>
      </Link>
    </li>
  );
  return (
    <>
      <aside
        id="layout-menu"
        className="layout-menu menu-vertical menu bg-menu-theme overflow-auto"
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        <div className="app-brand demo">
          {/* <a href="#!" className="app-brand-link">
            <img
              style={{ width: "65%" }}
              className="img-fluid"
              src={isHover ? fullLogo : !iscollapsed ? fullLogo : halfLogo}
              alt="logo-not-found"
            />
          </a> */}

          <a href="#!" className="app-brand-link">
            {console.log("isDarkMode:", isDarkMode)} {/* Debugging output */}
            <img
              style={{ width: "65%" }}
              className="img-fluid"
              src={
                theme === "dark"
                  ? isHover
                    ? fullLogoDark
                    : !iscollapsed
                    ? fullLogoDark
                    : halfLogoDark
                  : isHover
                  ? fullLogo
                  : !iscollapsed
                  ? fullLogo
                  : halfLogo
              }
              alt="logo-not-found"
            />
          </a>

          <div className="layout-menu-toggle menu-link text-large ms-auto cursor-pointer">
            <svg
              className="d-none d-xl-inline"
              onClick={handleToggleHover}
              width="20"
              height="20"
              viewBox="0 0 27 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="11" cy="11" r="10.5" stroke="#6F6B7D" />
              {!iscollapsed && <circle cx="11" cy="11" r="2" fill="#6F6B7D" />}
            </svg>
            <i
              onClick={() => setToggle(false)}
              className="ti ti-x d-block d-xl-none ti-sm align-middle"
            ></i>
          </div>
        </div>
        <ul className="menu-inner py-1">
          <li className="menu-item">
            <NavLink
              to={"/dashboard"}
              className="menu-link"
              onClick={() => setToggle(false)}
            >
              <i className="menu-icon tf-icons ti ti-smart-home" />
              <div>Dashboard</div>
            </NavLink>
          </li>
          {grantedLinks.map((item) => (
            <>
              <li className="menu-header small text-uppercase">
                <span className="menu-header-text">{item.section}</span>
              </li>
              {item?.links?.map((l) => (
                <li className="menu-item">
                  <NavLink to={l.path} className="menu-link">
                    {l.icon}
                    <div>{l.title}</div>
                  </NavLink>
                </li>
              ))}
            </>
          ))}
        </ul>

        {/* Bottom Section */}
        <div className="menu-bottom-section">
          <ul
            className={`menu-bottom-icons d-flex ${
              isHover ? `px-2` : !iscollapsed ? `px-2` : ``
            } align-items-center`}
          >
            {isHover
              ? fullBottomSection()
              : !iscollapsed
              ? fullBottomSection()
              : halfBottomSection()}
          </ul>
        </div>

        {/* Horizontal Bar for Paramètres, Profile, Logout */}
        {/* <div className="menu-horizontal-icons border-top pt-2 d-flex justify-content-between"> */}
        {/* <li className="menu-item">
            <NavLink to={"settings/company"} className="menu-link">
              <i className="menu-icon tf-icons ti ti-settings" />
              <div></div>
            </NavLink>
          </li>
          <li className="menu-item">
            <NavLink to={"team/profile"} className="menu-link">
              <i className="menu-icon tf-icons ti ti-user-circle" />
              <div></div>
            </NavLink>
          </li>
          <li className="menu-item">
            <Link onClick={() => localStorage.clear()} className="menu-link">
              <i className="menu-icon tf-icons ti ti-logout" />
              <div></div>
            </Link>
          </li> */}
        {/* </div> */}
      </aside>
    </>
  );
};

export default Sidebar;
