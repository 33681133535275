import {
  Box,
  // Button,
  ButtonBase,
  // Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  // IconButton,
  InputBase,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { useState } from "react";
// import EditIcon from "../../../../../assets/img/edit.svg";
// import { ReactComponent as DropArrow } from "../../../../../assets/icons/down-drop.svg";
// import { Formik, useFormik } from "formik";
import { useSelector } from "react-redux";
// import axiosInstance from "../../../../../apiInstances/axiosInstance";
import { toast } from "react-toastify";
import {
  // fetchPricing,
  saveUpdatePricing,
} from "../../../../../services/Pricing";
import { BsPlus, BsXLg } from "react-icons/bs";
// import { fetchSingleVehicleList } from "../../../../../Redux/vehicleSlice";
// import { useParams } from "react-router-dom";
// import { getVehicleById } from "../../../../../services/AddVehicle";
// import { fetchSingleSetting } from "../../../../../services/Drive";

// const defaultCountryValue = {
//   kms: [2000, 2500, 3000, 4000],
//   months: [18, 12, 6],
//   frais_administratifs: 0, // Per country
//   cout_km_supp: 0, // Per country
//   PP0: {
//     values: {
//       6: { 2000: 0, 2500: 0, 3000: 0, 4000: 0 },
//       12: { 2000: 0, 2500: 0, 3000: 0, 4000: 0 },
//       18: { 2000: 0, 2500: 0, 3000: 0, 4000: 0 },
//     },
//     acompte: 0, // Per PP
//   },
//   PP1: {
//     values: {
//       6: { 2000: 0, 2500: 0, 3000: 0, 4000: 0 },
//       12: { 2000: 0, 2500: 0, 3000: 0, 4000: 0 },
//       18: { 2000: 0, 2500: 0, 3000: 0, 4000: 0 },
//     },
//     acompte: 0, // Per PP
//   },
//   PP2: {
//     values: {
//       6: { 2000: 0, 2500: 0, 3000: 0, 4000: 0 },
//       12: { 2000: 0, 2500: 0, 3000: 0, 4000: 0 },
//       18: { 2000: 0, 2500: 0, 3000: 0, 4000: 0 },
//     },
//     acompte: 0, // Per PP
//   },
// };

const SingleTarification = ({ state, setState, details, isEdit, formik }) => {
  // const { id } = useParams();
  const { singleParamsData } = useSelector((state) => state?.vehicleSlice);

  const [askMonth, setAskMonth] = useState(false);
  const [askKms, setAskKms] = useState(false);
  // const [hideSection, setHideSection] = useState(true);
  // const [state, setState] = useState({
  //   country: "",
  //   PP: "PP0",
  //   counts: null,
  // });
  // const formik = useFormik({
  //   initialValues: {
  //     country: "",
  //     PP: "PP0",
  //     counts: null, // Removed variable, we are working per PP and country
  //   },
  // });

  // useEffect(() => {
  //   if (singleParamsData) {
  //     const countsObj = state?.counts ? state?.counts : {};
  //     singleParamsData.countries.forEach((c) => {
  //       if (!countsObj[c]) countsObj[c] = defaultCountryValue;
  //       else countsObj[c] = state?.counts?.[c];
  //     });
  //     setState({ ...state, counts: countsObj });
  //     // formik.setFieldValue("counts", countsObj);
  //     if (!state?.country) {
  //       setState({
  //         ...state,
  //         country: singleParamsData.countries[0],
  //       });
  //       // formik.setFieldValue("country", singleParamsData.countries[0]);
  //     }
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [singleParamsData]);

  const handleChange = (event, country, plan, month, km) => {
    if (plan !== "PP0") return;
    const { value } = event.target;
    const currentData = state?.counts;
    const updatedData = { ...currentData };

    updatedData[country][plan].values = {
      ...updatedData[country][plan].values,
    };
    updatedData[country][plan].values[month] =
      updatedData[country][plan].values[month] || {};
    updatedData[country][plan].values[month][km] = value;

    const PP1Acompte = state?.counts[state?.country].PP1.acompte;
    if (PP1Acompte) {
      const discount = Math.round(PP1Acompte / month - value);
      updatedData[country].PP1.values[month][km] = Math.abs(discount);
    }

    const PP2Acompte = state?.counts[state?.country].PP2.acompte;

    if (PP2Acompte) {
      const discount2 = Math.round(PP2Acompte / month - value);
      updatedData[country].PP2.values[month][km] = Math.abs(discount2);
    }

    setState({ ...state, counts: updatedData });
  };

  function validatePP02000km(data) {
    for (const countryCode in data) {
      const countryData = data[countryCode];
      const pp0Values = countryData.PP0?.values || {};

      // Flag to check if 2000km has a non-zero value for PP0
      let hasNonZero2000km = false;

      // Check each month in PP0 for a non-zero 2000km value
      for (const month in pp0Values) {
        const kmValues = pp0Values[month];
        if (parseInt(kmValues["2000"] || 0) > 0) {
          hasNonZero2000km = true;
          break;
        }
      }

      // Log result for each country
      if (!hasNonZero2000km) {
        toast.error(
          `Country ${countryCode} does not have a non-zero PP0 value for 2000km`
        );
        return false;
      }
    }

    // console.log("All countries have a non-zero PP0 value for 2000km.");
    return true;
  }

  const saveDetails = async () => {
    try {
      const payload = {
        model_id: details?.General?.model?._id,
        manufacturer_id: details?.General?.manufacturer?._id,
        declination: details?.General?.declination,
        values: state?.counts,
      };
      if (validatePP02000km(payload.values)) {
        await saveUpdatePricing(payload);
        toast.success("Updated successfully.");
        // setEdit(true);
      }
    } catch (err) {
      toast.error(err?.response?.data?.message || "Something went wrong");
    }
  };

  // useEffect(() => {
  //   getVehicleById(id).then((res) => {
  //     setDetails(res?.data);
  //   });
  // }, [id]);

  // useEffect(() => {
  //   if (details && id) {
  //     const payload = {
  //       model_id: details?.General?.model?._id,
  //       manufacturer_id: details?.General?.manufacturer?._id,
  //       declination: details?.General?.declination,
  //     };
  //     fetchPricing(payload)
  //       .then((res) => {
  //         setState({ ...state, counts: res?.values });
  //       })
  //       .catch((err) => {});
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [details]);

  const handleAcompteChange = (event) => {
    let newValue = event.target.value;

    // Remove leading zeros from input
    if (newValue.length > 1 && newValue[0] === "0") {
      newValue = newValue.slice(1);
    }

    // Deep copy of counts state to avoid unintended mutations
    const defaultValues = JSON.parse(JSON.stringify(state?.counts));

    // Direct reference to specific country data within the deep copied counts
    const countryData = defaultValues[state?.country];

    if (state?.PP === "PP0") {
      // If PP is PP0, set acompte to 0
      countryData[state?.PP].acompte = Number(0);
      return setState({ ...state, counts: defaultValues });
    }

    // Update acompte value for specific PP under the selected country
    countryData[state?.PP].acompte = Number(newValue);

    // Loop through each PP (if you want additional processing)
    for (let pp in countryData) {
      if (pp.startsWith("PP") && pp !== "PP0" && pp === state?.PP) {
        for (let month of countryData.months) {
          for (let km of countryData.kms) {
            let acompte = countryData.PP0.values[month][km];
            countryData[pp].values[month][km] =
              pp !== "PP0" && countryData.PP0.values[month][km]
                ? Math.abs(Math.round(newValue / month - acompte))
                : 0;
          }
        }
      }
    }

    setState({ ...state, counts: defaultValues });
  };

  const setCountryLevelValues = (country, key, value) => {
    // Deep clone the counts state to prevent unintended mutations
    const defaultValues = JSON.parse(JSON.stringify(state?.counts));

    // Access the specific country data
    const countryData = defaultValues[country];
    console.log(countryData);
    // Update the specific key for the country
    countryData[key] = value;

    // Update the counts state with the modified data for the specific country
    setState({ ...state, counts: defaultValues });
  };

  function addMonth(country, PP, newMonth) {
    const defaultValues = state?.counts;
    const countryData = defaultValues[country];
    let ppData = countryData;
    if (ppData.months.includes(newMonth)) return defaultValues;

    ppData.months.push(newMonth);
    ppData.PP0.values[newMonth] = {};
    ppData.PP1.values[newMonth] = {};
    ppData.PP2.values[newMonth] = {};
    ppData.kms.forEach((km) => {
      ppData.PP0.values[newMonth][km] = 0;
      ppData.PP1.values[newMonth][km] = 0;
      ppData.PP2.values[newMonth][km] = 0;
    });

    return defaultValues;
  }

  function removeMonth(country, PP, targetMonth) {
    const defaultValues = state?.counts;
    const countryData = defaultValues[country];
    let ppData = countryData;
    const monthIndex = ppData.months.indexOf(targetMonth);
    if (monthIndex === -1) return defaultValues;

    ppData.months.splice(monthIndex, 1);
    delete ppData.PP0.values[targetMonth];
    delete ppData.PP1.values[targetMonth];
    delete ppData.PP2.values[targetMonth];
    return defaultValues;
  }

  function addKms(country, PP, newKms) {
    const defaultValues = state?.counts;
    const countryData = defaultValues[country];
    let ppData = countryData;
    if (ppData.kms.includes(newKms)) return defaultValues;

    ppData.kms.push(newKms);
    for (const month in ppData.values) {
      ppData.PP0.values[month][newKms] = 0;
      ppData.PP1.values[month][newKms] = 0;
      ppData.PP2.values[month][newKms] = 0;
    }

    return defaultValues;
  }

  function removeKms(country, PP, targetKms) {
    const defaultValues = state?.counts;
    const countryData = defaultValues[country];
    let ppData = countryData;
    const kmsIndex = ppData.kms.indexOf(targetKms);
    if (kmsIndex === -1) return defaultValues;

    ppData.kms.splice(kmsIndex, 1);
    for (const month in ppData.values) {
      delete ppData.PP0.values[month][targetKms];
      delete ppData.PP1.values[month][targetKms];
      delete ppData.PP2.values[month][targetKms];
    }

    return defaultValues;
  }

  const handleAddMonths = (val) => {
    const res = addMonth(state?.country, state?.PP, Number(val));

    setState({ ...state, counts: res });
    setAskMonth(false);
  };

  const handleRemoveMonths = (m) => {
    const res = removeMonth(state?.country, state?.PP, Number(m));
    setState({ ...state, counts: res });
  };

  const handleAddKms = (val) => {
    const res = addKms(state?.country, state?.PP, Number(val));
    setState({ ...state, counts: res });
    setAskKms(false);
  };

  const handleRemoveKms = (m) => {
    const res = removeKms(state?.country, state?.PP, Number(m));
    setState({ ...state, counts: res });
  };

  const acompteValue =
    state?.counts?.[state?.country]?.[state?.PP].acompte || 0;
  const administratifsValue =
    state?.counts?.[state?.country]?.frais_administratifs || 0;
  const countKmSuppValue = state?.counts?.[state?.country]?.cout_km_supp || 0;
  const currentSection = state?.counts?.[state?.country] || null;

  console.log(formik.values, "country check");
  return (
    <Paper sx={{ mt: 2 }}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <div className="drive-section-head mb-3">Tarification</div>
      </Box>
      <Box sx={{ width: "100%", height: "100%" }}>
        {formik?.values?.country?.length === 0 && (
          <Box>
            <h6>Please configure country to set the Single Tarification.</h6>
          </Box>
        )}
        {formik?.values?.country?.length > 0 && (
          <Grid container>
            <Grid sm={3}>
              <Stack spacing={3}>
                <ButtonGroupOpts
                  options={["PP0", "PP1", "PP2"]}
                  value={state?.PP}
                  onChange={(val) => {
                    setState({ ...state, PP: val });
                  }}
                />

                <>
                  <div
                    className="drive-values-item"
                    style={{
                      width: "fit-content",
                      fontSize: "11px",
                      gap: "10px",
                    }}
                  >
                    Acompte
                    <InputBase
                      sx={{
                        height: 20,
                        width: 100,
                      }}
                      inputProps={{
                        style: {
                          textAlign: "right",
                          fontSize: "13px",
                        },
                      }}
                      placeholder="Acompte"
                      onChange={handleAcompteChange}
                      value={acompteValue}
                      disabled={isEdit}
                      key={`${state.country}-${state.PP}-Acompte`}
                      name={`${state.country}-${state.PP}-Acompte`}
                    />
                    €
                  </div>

                  <div
                    className="drive-values-item"
                    style={{
                      width: "fit-content",
                      fontSize: "11px",
                      gap: "10px",
                    }}
                  >
                    Frais administratifs
                    <InputBase
                      sx={{
                        height: 20,
                        width: 50,
                      }}
                      inputProps={{
                        style: {
                          textAlign: "right",
                          fontSize: "13px",
                        },
                      }}
                      placeholder="Frais administratifs"
                      value={administratifsValue}
                      onChange={({ target }) => {
                        let newValue = target.value;

                        // Allow only valid float values and prevent multiple leading zeros
                        if (
                          newValue.length > 1 &&
                          newValue[0] === "0" &&
                          newValue[1] !== "."
                        ) {
                          newValue = newValue.slice(1); // Remove leading zero
                        }

                        // Prevent "0" as a standalone value unless it's "0." for floats
                        if (newValue === "0") {
                          newValue = ""; // Clear the value
                        }

                        // Update the input value and call your set function
                        target.value = newValue;
                        setCountryLevelValues(
                          state?.country,
                          "frais_administratifs",
                          target.value
                        );
                      }}
                      disabled={isEdit}
                      key={`${state.country}-${state.PP}-frais_administratifs`}
                      name={`${state.country}-${state.PP}-frais_administratifs`}
                    />
                    €
                  </div>

                  <div
                    className="drive-values-item"
                    style={{
                      width: "fit-content",
                      fontSize: "11px",
                      gap: "10px",
                    }}
                  >
                    Cout/km supp
                    <InputBase
                      sx={{
                        height: 20,
                        width: 75,
                      }}
                      inputProps={{
                        style: {
                          textAlign: "right",
                          fontSize: "13px",
                        },
                      }}
                      placeholder="Cout/km supp"
                      value={countKmSuppValue}
                      onChange={({ target }) => {
                        let newValue = target.value;

                        // Allow only valid float values and prevent multiple leading zeros
                        if (
                          newValue.length > 1 &&
                          newValue[0] === "0" &&
                          newValue[1] !== "."
                        ) {
                          newValue = newValue.slice(1); // Remove leading zero
                        }

                        // Prevent "0" as a standalone value unless it's "0." for floats
                        if (newValue === "0") {
                          newValue = ""; // Clear the value
                        }

                        // Update the input value and call your set function
                        target.value = newValue;
                        setCountryLevelValues(
                          state?.country,
                          "cout_km_supp",
                          target.value
                        );
                      }}
                      disabled={isEdit}
                      key={`${state.country}-${state.PP}-cout_km_supp`}
                      name={`${state.country}-${state.PP}-cout_km_supp`}
                    />
                    €
                  </div>
                </>
              </Stack>
            </Grid>
            <Grid
              item
              sm={9}
              sx={{ maxWidth: "300px", overflow: "auto", py: 1 }}
            >
              {currentSection && (
                <Stack gap={2}>
                  {!isEdit && (
                    <Box
                      sx={{ width: "fit-content" }}
                      // width={12}
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      className="drive-values-item"
                    >
                      <ButtonBase
                        disabled={isEdit}
                        onClick={() => setAskMonth(true)}
                      >
                        <BsPlus />
                      </ButtonBase>
                    </Box>
                  )}
                  {currentSection?.months?.map((v, mIdx) => (
                    <Box display="flex" gap={1.5}>
                      <Box
                        sx={{
                          maxWidth: 120,
                          minWidth: 120,
                          ...(isEdit ? { border: "none" } : {}),
                        }}
                        className={`drive-values-item ${
                          isEdit ? `disabled-ip` : ``
                        }`}
                      >
                        {v} mois &nbsp;&nbsp;
                        {!isEdit && (
                          <ButtonBase
                            disabled={isEdit}
                            onClick={() => handleRemoveMonths(Number(v))}
                          >
                            <BsXLg />
                          </ButtonBase>
                        )}
                      </Box>
                      <Box display="flex" gap={1.5}>
                        {currentSection?.kms?.map((km, kmIdx) => (
                          <Box
                            width={150}
                            sx={{
                              maxWidth: 150,
                              minWidth: 150,
                              // border: "1px solid #000000",
                              // borderRadius: "6px",
                              display: "flex",
                              // pr: 1,
                              p: "0 5px 0 0",
                              alignItems: "center",
                              color: "#4B465C",
                            }}
                            className="drive-values-item"
                          >
                            <InputBase
                              value={
                                currentSection?.[state?.PP]?.values?.[v]?.[km]
                              }
                              sx={{ color: "#4B465C" }}
                              onFocus={(e) => {
                                e.target.value = "";
                                return e;
                              }}
                              inputProps={{
                                style: {
                                  textAlign: "center",
                                  fontWeight: 500,
                                  color: "#4B465C",
                                },
                              }}
                              onChange={(e) => {
                                const newValue = e.target.value;
                                if (
                                  newValue.length > 1 &&
                                  newValue[0] === "0"
                                ) {
                                  e.target.value = newValue.slice(1);
                                }
                                handleChange(
                                  e,
                                  state?.country,
                                  state?.PP,
                                  v,
                                  km
                                );
                              }}
                              disabled={
                                isEdit ||
                                state?.PP === "PP1" ||
                                state?.PP === "PP2"
                              }
                              type="number"
                            />
                            <Typography fontWeight={500}>€</Typography>
                          </Box>
                        ))}
                      </Box>
                    </Box>
                  ))}
                  <Box display="flex" gap={1.5}>
                    <Box width={120} sx={{ maxWidth: 120, minWidth: 120 }} />
                    {currentSection?.kms?.map((km) => (
                      <Box
                        width={120}
                        sx={{
                          textAlign: "center",
                          maxWidth: 150,
                          minWidth: 150,
                          ...(isEdit ? { border: "none" } : {}),
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                        className={`drive-values-item ${
                          isEdit ? `disabled-ip` : ``
                        }`}
                      >
                        {km} km &nbsp;&nbsp;
                        {!isEdit && (
                          <ButtonBase
                            disabled={isEdit}
                            onClick={() => handleRemoveKms(Number(km))}
                          >
                            <BsXLg />
                          </ButtonBase>
                        )}
                      </Box>
                    ))}
                    {!isEdit && (
                      <Box
                        sx={{ width: "fit-content" }}
                        // width={12}
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        className="drive-values-item"
                      >
                        <ButtonBase
                          disabled={isEdit}
                          onClick={() => setAskKms(true)}
                        >
                          <BsPlus />
                        </ButtonBase>
                      </Box>
                    )}
                  </Box>
                </Stack>
              )}
            </Grid>
          </Grid>
        )}
      </Box>
      {askMonth && (
        <AskValueDialog
          onClose={() => setAskMonth(false)}
          onSubmit={handleAddMonths}
        />
      )}
      {askKms && (
        <AskValueDialog
          onClose={() => setAskKms(false)}
          onSubmit={handleAddKms}
        />
      )}
    </Paper>
  );
};

export default SingleTarification;

const AskValueDialog = ({ onClose, onSubmit }) => {
  const [val, setVal] = useState(0);
  return (
    <Dialog open fullWidth maxWidth="xs">
      <Box sx={{ pt: 2, pl: 3 }}>
        <strong>Please Provide Value</strong>
      </Box>
      <DialogContent>
        <InputBase
          sx={{
            border: "1px solid #000000",
            borderRadius: "6px",
            px: 2,
          }}
          onChange={(e) => setVal(e.target.value)}
          type="number"
          value={val}
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <button
          type="submit"
          className="btn btn-danger waves-effect waves-light"
          onClick={onClose}
        >
          Cancel
        </button>
        <button
          type="submit"
          className="btn btn-primary waves-effect waves-light"
          onClick={() => onSubmit(val)}
        >
          Submit
        </button>
      </DialogActions>
    </Dialog>
  );
};

const ButtonGroupOpts = ({ options, value, onChange }) => {
  return (
    <div className="d-flex gap-1 flex-wrap">
      {options.map((opt) => (
        <div
          className={`drive-values-item ${value === opt ? `selected` : ``}`}
          role="button"
          onClick={() => onChange(opt)}
        >
          {opt}
        </div>
      ))}
    </div>
  );
};
