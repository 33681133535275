import React from "react";
import Modal from "react-bootstrap/Modal";
import { deleteClient } from "../../../services/client/addclientApi";
import { useDispatch } from "react-redux";
import { fetchAllUserList } from "../../../Redux/commonSlice";

function DeleteModal(props) {
  console.log(props);
  const dispatch = useDispatch();
  const deleteData = async () => {
    const data = await deleteClient(props.clientId);
    console.log(data);
    // if (data.status) {
    props.onHide();
    dispatch(fetchAllUserList());
    window.history.back();
    // }
  };
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Supprimer le Client
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Êtes-vous sûr de supprimer le client ?</p>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-secondary" onClick={props.onHide}>
          Annuler
        </button>
        <button className="btn btn-danger" onClick={() => deleteData()}>
          Supprimer
        </button>
      </Modal.Footer>
    </Modal>
  );
}
export default DeleteModal;
