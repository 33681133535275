import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  Chip,
  Collapse,
  Grid,
  IconButton,
  InputBase,
  Paper,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import { Dropdown } from "primereact/dropdown"; // Import Dropdown from PrimeReact
import EditIcon from "../../../../../assets/img/edit.svg";
import { ReactComponent as DropArrow } from "../../../../../assets/icons/down-drop.svg";
import { ReactComponent as CrossIcon } from "../../../../../assets/icons/x.svg";
import {
  getVehicle,
  getVehicleById,
  getVehicleWithTypes,
} from "../../../../../services/AddVehicle";
import { toast } from "react-toastify";
import {
  fetchSingleSetting,
  saveUpdateSingleSetting,
} from "../../../../../services/Drive";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setSingleParamsData } from "../../../../../Redux/vehicleSlice";
import SingleTarification from "./SingleTarification";
import CountrySelection from "./SingleParams/CountrySelection";
import Suggestions from "./MultiParams/Suggestions";
import Badges from "./MultiParams/Badges";
import { BsCheck, BsX } from "react-icons/bs";
import { useSelector } from "react-redux";
import {
  fetchPricing,
  saveUpdatePricing,
} from "../../../../../services/Pricing";

export const schengenCountries = [
  { country: "Austria", code: "AT" },
  { country: "Belgium", code: "BE" },
  { country: "Czech Republic", code: "CZ" },
  { country: "Denmark", code: "DK" },
  { country: "Estonia", code: "EE" },
  { country: "Finland", code: "FI" },
  { country: "France", code: "FR" },
  { country: "Germany", code: "DE" },
  { country: "Greece", code: "GR" },
  { country: "Hungary", code: "HU" },
  { country: "Iceland", code: "IS" },
  { country: "Italy", code: "IT" },
  { country: "Latvia", code: "LV" },
  { country: "Liechtenstein", code: "LI" },
  { country: "Lithuania", code: "LT" },
  { country: "Luxembourg", code: "LU" },
  { country: "Malta", code: "MT" },
  { country: "Netherlands", code: "NL" },
  { country: "Norway", code: "NO" },
  { country: "Poland", code: "PL" },
  { country: "Portugal", code: "PT" },
  { country: "Slovakia", code: "SK" },
  { country: "Slovenia", code: "SI" },
  { country: "Spain", code: "ES" },
  { country: "Sweden", code: "SE" },
  { country: "Switzerland", code: "CH" },
];

// Utility function to group vehicles by manufacturer and model
const groupVehicles = (vehicleData) => {
  const groupedMap = vehicleData.reduce((acc, vehicle) => {
    vehicle.title = `${vehicle.manufacturer} ${vehicle.model}`;
    const { manufacturer, model, color, ...restFields } = vehicle;

    const colorName = color?.name;
    const key = `${manufacturer}-${model}`;

    if (!acc[key]) {
      acc[key] = {
        manufacturer,
        model,
        colors: [],
        ...restFields,
      };
    }

    const colorIndex = acc[key].colors.findIndex((c) => c.color === colorName);

    if (colorIndex === -1) {
      acc[key].colors.push({
        color: colorName,
        vehicles: [vehicle],
      });
    } else {
      acc[key].colors[colorIndex].vehicles.push(vehicle);
    }

    return acc;
  }, {});

  return Object.values(groupedMap);
};

const defaultCountryValue = {
  kms: [2000, 2500, 3000, 4000],
  months: [18, 12, 6],
  frais_administratifs: 0, // Per country
  cout_km_supp: 0, // Per country
  PP0: {
    values: {
      6: { 2000: 0, 2500: 0, 3000: 0, 4000: 0 },
      12: { 2000: 0, 2500: 0, 3000: 0, 4000: 0 },
      18: { 2000: 0, 2500: 0, 3000: 0, 4000: 0 },
    },
    acompte: 0, // Per PP
  },
  PP1: {
    values: {
      6: { 2000: 0, 2500: 0, 3000: 0, 4000: 0 },
      12: { 2000: 0, 2500: 0, 3000: 0, 4000: 0 },
      18: { 2000: 0, 2500: 0, 3000: 0, 4000: 0 },
    },
    acompte: 0, // Per PP
  },
  PP2: {
    values: {
      6: { 2000: 0, 2500: 0, 3000: 0, 4000: 0 },
      12: { 2000: 0, 2500: 0, 3000: 0, 4000: 0 },
      18: { 2000: 0, 2500: 0, 3000: 0, 4000: 0 },
    },
    acompte: 0, // Per PP
  },
};

const SingleParams = () => {
  const { singleParamsData } = useSelector((state) => state.vehicleSlice);
  const dispatch = useDispatch();
  const { id } = useParams();
  const [isEdit, setEdit] = useState(true);
  const [reload, setReload] = useState(true);
  const [hideSection, setHideSection] = useState(true);
  const [vehicles, setVehicles] = useState([]);
  const [state, setState] = useState({
    country: "",
    PP: "PP0",
    counts: null,
  });
  const [details, setDetails] = useState(null);

  useEffect(() => {
    if (singleParamsData) {
      const countsObj = state.counts ? state.counts : {};
      singleParamsData.countries.forEach((c) => {
        if (!countsObj[c]) countsObj[c] = { ...defaultCountryValue };
        else countsObj[c] = { ...state.counts?.[c] };
      });
      setState((prev) => ({ ...prev, counts: { ...countsObj } }));
      if (!state.country) {
        setState((prev) => ({
          ...prev,
          country: singleParamsData.countries[0],
        }));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [singleParamsData]);

  useEffect(() => {
    getVehicleById(id).then((res) => {
      setDetails(res?.data);
    });
  }, [id]);

  useEffect(() => {
    if (details && id) {
      const payload = {
        model_id: details?.General?.model?._id,
        manufacturer_id: details?.General?.manufacturer?._id,
        declination: details?.General?.declination,
      };
      fetchPricing(payload)
        .then((res) => {
          setState((prev) => ({ ...prev, counts: res?.values }));
        })
        .catch((err) => {});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [details]);

  useEffect(() => {
    getVehicleWithTypes().then((res) => setVehicles(groupVehicles(res.data)));
  }, []);

  useEffect(() => {
    if (id && vehicles.length > 0) {
      fetchSingleSetting(id).then((res) => {
        dispatch(setSingleParamsData(res));
        formik.setFieldValue("badges", res?.badges);

        const finalVals = vehicles
          .map((v) => {
            const check = res?.suggestions?.find(
              (s) => s.model_id === v.vehicle_id
            );
            if (check) {
              v.color = check.color;
              return v;
            }
            return false;
          })
          .filter((r) => r);
        formik.setFieldValue("vehicleSelection", finalVals);
        formik.setFieldValue(
          "country",
          res?.countries?.map((f) => {
            return schengenCountries.find((r) => r.code === f);
          })
        );
        formik.setFieldValue("caution", res?.caution);
        formik.setFieldValue("averageDeliveryTime", res?.average_delivery_time);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, vehicles, reload]);

  function validatePP02000km(data) {
    const countryCode = state.country;
    const countryData = data[countryCode];

    if (!countryData) {
      console.error(`Country ${countryCode} not found in data`);
      return true;
    }

    const pp0Values = countryData.PP0?.values || {};

    // Flag to check if 2000km has a non-zero value for PP0
    let hasNonZero2000km = false;

    // Check each month in PP0 for a non-zero 2000km value
    for (const month in pp0Values) {
      const kmValues = pp0Values[month];
      if (parseInt(kmValues["2000"] || 0) > 0) {
        hasNonZero2000km = true;
        break;
      }
    }

    // Log result for the specified country
    if (!hasNonZero2000km) {
      toast.error(
        `Country ${countryCode} does not have a non-zero PP0 value for 2000km`
      );
      return false;
    }

    // console.log(`Country ${countryCode} has a non-zero PP0 value for 2000km.`);
    return true;
  }

  const saveDetails = async () => {
    try {
      const payload = {
        model_id: details?.General?.model?._id,
        manufacturer_id: details?.General?.manufacturer?._id,
        declination: details?.General?.declination,
        values: state?.counts,
      };

      if (validatePP02000km(payload.values)) {
        await saveUpdatePricing(payload);
        // toast.success("Updated successfully.");
        // setEdit(true);
        return true;
      } else {
        return false;
      }
    } catch (err) {
      console.log(err);
      toast.error(err?.response?.data?.message || "Something went wrong");
    }
  };

  // Initialize Formik for state management and submission
  const formik = useFormik({
    initialValues: {
      vehicleSelection: [], // Stores selected vehicles with color choices
      badges: [], // Stores selected badges
      country: [], // Stores selected countries
      caution: "", // Stores selected caution value
      averageDeliveryTime: "", // Stores average delivery time
    },
    onSubmit: async (values) => {
      if (values.country?.length > 0 && state?.counts) {
        const firstSave = await saveDetails();
        if (!firstSave) return;
      }

      const finalData = {
        suggestions: values.vehicleSelection.map((selected) => ({
          manufacturer_id: selected.manufacturer_id,
          model_id: selected.vehicle_id,
          color: selected.color || "Default", // Assign "Default" if no color is selected
        })),
        badges: values.badges,
        countries: values.country?.map((r) => r?.code),
        caution: values.caution,
        average_delivery_time: values.averageDeliveryTime,
      };

      try {
        await saveUpdateSingleSetting(id, finalData);
        setReload(!reload);
        toast.success("Updated successfully.");
        setEdit(true);
      } catch (err) {
        console.log(err);
        toast.error(err?.response?.data?.message || "Something went wrong");
      }
    },
  });

  // // Function to update the vehicle selection in Formik
  // const handleVehicleSelection = (event, newValue) => {
  //   formik.setFieldValue("vehicleSelection", newValue);
  // };

  // // Function to update badges in Formik
  // const handleBadgeChange = (event, newValue) => {
  //   formik.setFieldValue("badges", newValue);
  // };

  console.log(state);

  return (
    <Paper sx={{ boxShadow: "0px 4px 18px 0px #4B465C1A", p: 2 }}>
      <form onSubmit={formik.handleSubmit}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography
            color="#4B465C"
            fontSize={18}
            fontWeight={500}
            role="button"
            onClick={() => setHideSection(!hideSection)}
          >
            Paramètres Single &nbsp;
            <DropArrow height={18} />
          </Typography>
          {isEdit ? (
            <IconButton size="small" onClick={() => setEdit(false)}>
              <img src={EditIcon} alt="Edit" width={24} height={24} />
            </IconButton>
          ) : (
            <></>
          )}
        </Box>
        <Collapse
          collapsedSize={0}
          in={hideSection}
          timeout="auto"
          unmountOnExit
        >
          <div className="row gap-2 mt-2">
            <div className="col-2">
              <div className="drive-section-head mb-2">Pays disponible(s)</div>
              <CountrySelection
                schengenCountries={schengenCountries}
                isEdit={isEdit}
                formik={formik}
                onChange={(val) => {
                  setState((prev) => ({
                    ...prev,
                    country: val,
                  }));
                }}
                onAdd={(val) => {
                  setTimeout(() => {
                    setState((prev) => ({
                      ...prev,
                      country: val,
                      counts: Object.assign(
                        { [val]: defaultCountryValue },
                        state.counts
                      ),
                    }));
                  }, 1000);
                }}
                value={state?.country}
              />
            </div>
            <div className="col-2">
              <div className="drive-section-head mb-2">Caution du véhicule</div>
              <input
                className={`drive-values-item plain-input ${
                  !isEdit ? "" : "no-border"
                }`}
                placeholder="Enter caution amount"
                onChange={(e) =>
                  formik.setFieldValue("caution", e.target.value)
                }
                disabled={isEdit}
                value={formik.values.caution}
              />
            </div>
            <div className="col-2">
              <div className="drive-section-head mb-2">
                Délai moyen de livraison
              </div>

              <select
                className={`drive-values-item plain-input ${
                  !isEdit ? "" : "no-border"
                }`}
                placeholder="Enter average delivery time"
                onChange={(e) =>
                  formik.setFieldValue("averageDeliveryTime", e.target.value)
                }
                disabled={isEdit}
                value={formik.values.averageDeliveryTime}
              >
                <option value="">Select average delivery time</option>
                <option value="Maintenant">Maintenant</option>
                <option value="2_semaines">2 semaines</option>
                <option value="3_semaines">3 semaines</option>
                <option value="1_mois">1 mois</option>
                <option value="2_mois">2 mois</option>
                <option value="3_mois">3 mois</option>
                <option value="6_mois">6 mois</option>
              </select>
            </div>
            <div className="col-2">
              <Badges formik={formik} isEdit={isEdit} />
            </div>
            <div className="col-3">
              <Suggestions
                formik={formik}
                isEdit={isEdit}
                vehicles={vehicles}
              />
            </div>

            <div className="col-12">
              <SingleTarification
                state={state}
                setState={(val) => setState(val)}
                details={details}
                isEdit={isEdit}
                formik={formik}
              />
            </div>
          </div>

          {!isEdit && (
            <div className="d-flex gap-2 justify-content-end mt-3">
              <button
                type="submit"
                className="btn btn-primary waves-effect waves-light"
              >
                <BsCheck style={{ fontSize: "20px" }} />
                &nbsp;&nbsp; VALIDER
              </button>
              <button onClick={() => setEdit(true)} className="btn border">
                <BsX style={{ fontSize: "20px" }} />
                &nbsp;&nbsp; ANNULER
              </button>
            </div>
          )}
        </Collapse>
      </form>
    </Paper>
  );
};

export default SingleParams;
