/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import BreadCrumbs from "../../Common/BreadCrumbs";
import tagifyMin from "@yaireo/tagify";
import { Form, Formik } from "formik";
import { useDispatch } from "react-redux";
import { addDocument, addVehicle } from "../../../services/AddVehicle";
import GeneralInformation from "./components/GeneralInformation";
import InsuranceInformation from "./components/InsuranceInformation";
import Variables from "./components/Variables";
import FinancialInformation from "./components/FinancialInformation";
import General from "./components/General";
import DamageAndMaintenance from "./components/DamageAndMaintenance";
import Documents from "./components/Documents";
import {
  fetchFuelDropdown,
  fetchManufacturerDropdown,
  fetchVehicleList,
} from "../../../Redux/vehicleSlice";
import {
  fetchVehicleCategoryDropdown,
  fetchcompanyDropdown,
  fetchfinancetypeDropdown,
} from "../../../Redux/commonSlice";
import { NavLink, useNavigate } from "react-router-dom";
import { validationSchema } from "../validationSchema";
import GeneralModal from "../Editvehicle/Modal/GeneralModal";
import { Paper } from "@mui/material";

const Addvehicle = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const fileInputRef = useRef();
  // const inputRef = useRef();
  const [selectedImage, setSelectedImage] = useState([]);
  const [istags, setTags] = useState("");

  // tages Start
  const jsonArray = istags ? JSON.parse(istags) : [];
  const tagsall = jsonArray.map((item) => ({ name: item?.value }));
  // useEffect(() => {
  //   const tagify = new tagifyMin(inputRef.current);

  //   return () => {
  //     tagify.destroy();
  //   };
  // }, []);
  // tages end

  // multiple-imge upload Start
  const openFileInput = () => {
    fileInputRef.current.click();
  };
  const uploadImge = (e) => {
    const selectedFile = e.target.files;
    if (selectedFile && selectedFile.length > 0) {
      const newImages = Array.from(selectedFile).map((file) => {
        return {
          file: file,
          source: URL.createObjectURL(file),
        };
      });
      setSelectedImage((prevImages) => [...prevImages, ...newImages]);
    }
  };
  // multiple-imge upload end

  // removeFile Start
  const removeFile = (index, e) => {
    let temImgData = selectedImage.filter((_, i) => i !== index);
    setSelectedImage(temImgData);
    e.stopPropagation();
  };
  // removeFile end

  const initialValues = {
    generalInfo: {
      main_fuel: "",
      second_fuel: "",
      chassis_number: "",
      license_plate: "",
      co2_emissions: "",
      k_w: "",
      resume: "",
      displacement: "",
      good: "",
      tank_size: "",
      tax_power: "",
      transmission: "",
      seat_name: "",
      no_doors: "",
      tags: ["", ""],
      vi_types: "",
    },
    insuranceInfo: {
      issuer: "",
      date_issue: "",
      support_contact: "",
      flight_franchise: "",
      franchise_crash: "",
      total_claims: "",
      info: "",
    },
    variables: [{}],
    financialInfo: {
      registered_by: "",
      finance_type: "",
      purchase_option: false,
      funded_by: "",
      soon_suite: "",
    },
    damageMaintenance: {
      next_mileage: "",
      next_date: "",
      pre_mileage: "",
      pre_date: "",
    },
    damage: [{}],
    general: {
      company: "",
      manufacturer: "",
      model: "",
      vehicle_category: "",
      vehicle_location: "",
      year: new Date().getFullYear() || "",
      color: "",
      status: "",
      active: "",
      min_age_driver: "23",
      license_class: "B" || "",
      declination: "",
    },
  };

  useEffect(() => {
    dispatch(fetchManufacturerDropdown());
    dispatch(fetchFuelDropdown());
    dispatch(fetchVehicleCategoryDropdown());
    dispatch(fetchfinancetypeDropdown());
    dispatch(fetchcompanyDropdown());
  }, []);
  return (
    <div className="content-wrapper ">
      {/* <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        validateOnChange={false}
        validateOnBlur={false}
        validationSchema={validationSchema}
        onSubmit={async (values) => {
          if (Array.isArray(values.damage) && values.damage.length === 0) {
            delete values.damage;
          }
          const formData = new FormData();
          const body = values;
          body.generalInfo.tags = tagsall;
          console.log("values", values);
          body.generalInfo.main_fuel = values?.generalInfo?.main_fuel?._id;
          body.generalInfo.second_fuel = values?.generalInfo?.second_fuel?._id;
          body.financialInfo.finance_type =
            values?.financialInfo?.finance_type?._id;
          body.general.company = values?.general?.company?._id;
          body.general.manufacturer = values?.general?.manufacturer?._id;
          body.general.model = values?.general?.model?._id;
          body.general.vehicle_category =
            values?.general?.vehicle_category?._id;
          const data = await addVehicle(values);
          if (data) {
            formData.append("general_info", data?.data.GeneralInfo._id);
            selectedImage?.map((item) =>
              formData.append("document", item.file)
            );
            await addDocument(formData);
            dispatch(fetchVehicleList());
            navigate("/fleet");
          }
        }}
      >
        {(props) => {
          const { handleChange, values, errors, touched } = props;
          console.log(touched, "errors");
          return (
            <Form>
              <div className="container-fluid flex-grow-1 container-p-y">
                <div className="d-flex align-items-center justify-content-between">
                  <BreadCrumbs title={"Flotte"} subTitle={"Nouveau Véhicule"} />
                  <h4 className="d-flex gap-3">
                    <NavLink
                      className="btn btn-label-danger waves-effect"
                      type="button"
                      to={"/fleet"}
                    >
                      Annuler
                    </NavLink>

                    <button
                      type="submite"
                      className="btn btn-primary waves-effect waves-light"
                    >
                      Publier
                    </button>
                  </h4>
                </div>
                <div>
                  <div className="row">
                    <div className="col-12 col-lg-8">
                      <GeneralInformation
                        errors={errors}
                        ref={inputRef}
                        setTags={setTags}
                        handleChange={handleChange}
                        values={values}
                      />
                      <InsuranceInformation
                        errors={errors}
                        handleChange={handleChange}
                        values={values}
                      />
                      <Variables handleChange={handleChange} values={values} />
                      <FinancialInformation
                        handleChange={handleChange}
                        values={values}
                      />
                    </div>
                    <div className="col-12 col-lg-4">
                      <General
                        handleChange={handleChange}
                        values={values}
                        errors={errors}
                      />
                      <DamageAndMaintenance
                        handleChange={handleChange}
                        values={values}
                      />
                      <Documents
                        uploadImge={uploadImge}
                        openFileInput={openFileInput}
                        removeFile={removeFile}
                        selectedImage={selectedImage}
                        ref={fileInputRef}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik> */}
      <div className="container-fluid flex-grow-1 container-p-y">
        <div className="d-flex align-items-center justify-content-between">
          <BreadCrumbs title={"Flotte"} subTitle={"Nouveau Véhicule"} />
          {/* <h4 className="d-flex gap-3">
            <NavLink
              className="btn btn-label-danger waves-effect"
              type="button"
              to={"/fleet"}
            >
              Annuler
            </NavLink>

            <button
              type="submite"
              className="btn btn-primary waves-effect waves-light"
            >
              Publier
            </button>
          </h4> */}
        </div>
        <Paper sx={{ boxShadow: "0px 4px 18px 0px #4B465C1A", p: 2 }}>
          <GeneralModal show={true} onHide={() => navigate("/fleet")} />
        </Paper>
      </div>
    </div>
  );
};

export default Addvehicle;
