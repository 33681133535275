import {
  Box,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  Paper,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import EditIcon from "../../../../assets/img/edit.svg";
import { useOutletContext, useParams } from "react-router-dom";
import GeneralModal from "../Modal/GeneralModal";
import ColorModal from "../Modal/ColorModal";
import * as Yup from "yup";
import { Field, Formik } from "formik";
import { Dropdown } from "primereact/dropdown";
import { demo2 } from "../../../Common/DropdownValue";
import { fetchSingleVehicleList } from "../../../../Redux/vehicleSlice";
import { updateVehicle } from "../../../../services/AddVehicle";

const FleetGeneral = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [modalShow, setModalShow] = useState(false);
  const [isColorModalShow, setColorModalShow] = useState(false);
  const [isEdit, setEdit] = useState(true);
  const [isEdit2, setEdit2] = useState(true);

  const { headerImage } = useOutletContext(); // Access the props passed from parent
  const { singlevehicleData, singlevehicleDocument, fuleDropdown } =
    useSelector((state) => state.vehicleSlice);

  const validationSchema = Yup.object().shape({
    main_fuel: Yup.mixed().required("required *"),
    chassis_number: Yup.mixed().required("required *"),
    co2_emissions: Yup.string().required("required *"),
    tax_power: Yup.number().required("required *"),
    license_plate: Yup.string().required("required *"),
  });

  const generalInfo = {
    main_fuel: singlevehicleData?.GeneralInfo?.main_fuel || "",
    second_fuel: singlevehicleData?.GeneralInfo?.second_fuel || "",
    chassis_number: singlevehicleData?.GeneralInfo?.chassis_number || "",
    license_plate: singlevehicleData?.GeneralInfo?.license_plate || "",
    co2_emissions: singlevehicleData?.GeneralInfo?.co2_emissions || "",
    k_w: singlevehicleData?.GeneralInfo?.k_w || "",
    resume: singlevehicleData?.GeneralInfo?.resume || "",
    displacement: singlevehicleData?.GeneralInfo?.displacement || "",
    good: singlevehicleData?.GeneralInfo?.good || "",
    tank_size: singlevehicleData?.GeneralInfo?.tank_size || "",
    tax_power: singlevehicleData?.GeneralInfo?.tax_power || "",
    transmission: singlevehicleData?.GeneralInfo?.transmission || "",
    seat_name: singlevehicleData?.GeneralInfo?.seat_name || "",
    no_doors: singlevehicleData?.GeneralInfo?.no_doors || "",
  };

  const validationSchema2 = Yup.object().shape({
    date_issue: Yup.date().required("required *"),
    issuer: Yup.string().required("required *"),
  });

  const insuranceInfo = {
    issuer: singlevehicleData?.InsuranceInfo?.issuer || "",
    date_issue: singlevehicleData?.InsuranceInfo?.date_issue
      ? new Date(singlevehicleData?.InsuranceInfo?.date_issue)
          ?.toISOString()
          ?.split("T")[0]
      : "",
    support_contact: singlevehicleData?.InsuranceInfo?.support_contact || "",
    flight_franchise: singlevehicleData?.InsuranceInfo?.flight_franchise || "",
    franchise_crash: singlevehicleData?.InsuranceInfo?.franchise_crash || "",
    total_claims: singlevehicleData?.InsuranceInfo?.total_claims || "",
    info: singlevehicleData?.InsuranceInfo?.info || "",
  };

  return (
    <Box mt={3}>
      <Paper sx={{ boxShadow: "0px 4px 18px 0px #4B465C1A" }}>
        <Grid container>
          <Grid item sm={4}>
            <Box px={4} py={2} display="flex" flexDirection="column" gap={2}>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                {singlevehicleData?.General?.color?.image ? (
                  <img
                    className="single-color"
                    src={`${process.env.REACT_APP_IMG_URL}${singlevehicleData?.General?.color?.image}`}
                    alt=""
                  />
                ) : (
                  <div></div>
                )}
                <IconButton
                  size="small"
                  onClick={() => setColorModalShow(true)}
                >
                  <img src={EditIcon} alt="Edit" width={24} height={24} />
                </IconButton>
              </Box>
              <Box className="single-car mb-4" mt={-4}>
                {headerImage && (
                  <img
                    src={`${process.env.REACT_APP_IMG_URL}${headerImage}`}
                    alt=""
                    width={200}
                    style={{
                      objectFit: "cover",
                      objectPosition: "center",
                    }}
                  />
                )}
                {/* <div>
                  {singlevehicleDocument?.length > 0 && (
                    <img
                      src={`${process.env.REACT_APP_IMG_URL}${singlevehicleDocument?.[0]?.document?.[0]}`}
                      alt=""
                      width={200}
                      style={{
                        objectFit: "cover",
                        objectPosition: "center",
                      }}
                    />
                  )}
                </div> */}
                <div className="mx-4 d-flex justify-content-evenly mt-4">
                  <div className="badge badge-center rounded-pill bg-label-success me-2">
                    {singlevehicleData?.General?.active === "Active" ? (
                      <span className="badge badge-center rounded-pill w-px-30 h-px-30 me-2 bg-label-success">
                        <i className="ti ti-sm ti-check"></i>
                      </span>
                    ) : (
                      <span className="badge badge-center rounded-pill w-px-30 h-px-30 me-2 bg-label-danger">
                        <i className="ti ti-sm text-danger"> x</i>
                      </span>
                    )}
                  </div>
                  <div>
                    <h4 className="mb-0 text-center">
                      {singlevehicleData?.General?.manufacturer?.name}{" "}
                      {singlevehicleData?.General?.model?.name}{" "}
                      {singlevehicleData?.General?.declination}
                    </h4>

                    <div className="d-flex justify-content-center gap-3">
                      <div className="text-lighter">
                        {singlevehicleData?.GeneralInfo?.license_plate}
                      </div>
                      <div className="text-lighter">Luxembourg</div>
                    </div>
                  </div>
                  <div className="badge badge-center bg-label-danger ms-2">
                    {singlevehicleData?.General?.vehicle_category?.name}
                  </div>
                </div>
              </Box>
            </Box>
          </Grid>
          <Divider
            orientation="vertical"
            sx={{ borderColor: "#DBDADE" }}
            flexItem
          />
          <Grid item sm={7.9}>
            <Box px={3} py={2}>
              <Box display="flex" justifyContent="space-between">
                <Typography color="#4B465C" fontSize={18} fontWeight={500}>
                  Information Primaire
                </Typography>
                <IconButton size="small" onClick={() => setModalShow(true)}>
                  <img src={EditIcon} alt="Edit" width={24} height={24} />
                </IconButton>
              </Box>
              {modalShow && (
                <GeneralModal
                  show={modalShow}
                  onHide={() => setModalShow(false)}
                />
              )}
              {!modalShow && (
                <Grid container mt={3}>
                  <Grid item md={2.4} lg={2.4} xl={2.4} sm={4} xs={4}>
                    <div className="fleet-info-item-head">Marque</div>
                    <div className="fleet-info-item-desc">
                      {singlevehicleData?.General?.manufacturer?.name}
                    </div>
                  </Grid>
                  <Grid item md={2.4} lg={2.4} xl={2.4} sm={4} xs={4}>
                    <div className="fleet-info-item-head">Modèle</div>
                    <div className="fleet-info-item-desc">
                      {singlevehicleData?.General?.model?.name}
                    </div>
                  </Grid>
                  <Grid item md={2.4} lg={2.4} xl={2.4} sm={4} xs={4}>
                    <div className="fleet-info-item-head">Déclinaison</div>
                    <div className="fleet-info-item-desc">
                      {singlevehicleData?.General?.declination}
                    </div>
                  </Grid>
                  <Grid item md={2.4} lg={2.4} xl={2.4} sm={4} xs={4}>
                    <div className="fleet-info-item-head">Type</div>
                    <div className="fleet-info-item-desc">
                      {singlevehicleData?.GeneralInfo?.vi_types}
                    </div>
                  </Grid>
                  <Grid item md={2.4} lg={2.4} xl={2.4} sm={4} xs={4}>
                    <div className="fleet-info-item-head">Activité</div>
                    <div className="fleet-info-item-desc">
                      {singlevehicleData?.General?.active}
                    </div>
                  </Grid>
                </Grid>
              )}
              {!modalShow && (
                <Grid container mt={4} spacing={1}>
                  <Grid item md={2} lg={2} xl={2} sm={4} xs={4}>
                    <div className="fleet-info-item-head">
                      Affiché sur le site
                    </div>
                    <div className="fleet-info-item-desc">
                      <FormControlLabel
                        label="Multi"
                        control={<Checkbox size="small" />}
                        checked={singlevehicleData?.General?.is_multi}
                        sx={{
                          ".MuiFormControlLabel-label": {
                            fontSize: "13px !important",
                            color: "#4B465C !important",
                          },

                          ".MuiCheckbox-root": {
                            padding: "0 !important",
                            ml: "5px",
                          },

                          ".MuiFormControlLabel-root": {
                            marginLeft: "0px !important",
                          },
                          marginLeft: "0px !important",
                        }}
                        labelPlacement="start"
                      />
                      <FormControlLabel
                        label="Single"
                        control={<Checkbox size="small" />}
                        checked={singlevehicleData?.General?.is_single}
                        sx={{
                          ".MuiFormControlLabel-label": {
                            fontSize: "13px !important",
                            color: "#4B465C !important",
                          },
                          ".MuiCheckbox-root": {
                            padding: "0 !important",
                            ml: "5px",
                          },
                        }}
                        labelPlacement="start"
                      />
                    </div>
                  </Grid>
                  <Grid item md={2} lg={2} xl={2} sm={4} xs={4}>
                    <div className="fleet-info-item-head">Année</div>
                    <div className="fleet-info-item-desc">
                      {singlevehicleData?.General?.year}
                    </div>
                  </Grid>
                  <Grid item md={2} lg={2} xl={2} sm={4} xs={4}>
                    <div className="fleet-info-item-head">Propriétaire</div>
                    <div className="fleet-info-item-desc">
                      {singlevehicleData?.General?.company?.name}
                    </div>
                  </Grid>
                  <Grid item md={2} lg={2} xl={2} sm={4} xs={4}>
                    <div className="fleet-info-item-head">Statuts</div>
                    <div className="fleet-info-item-desc">
                      {singlevehicleData?.General?.status}
                    </div>
                  </Grid>
                  <Grid item md={2} lg={2} xl={2} sm={4} xs={4}>
                    <div className="fleet-info-item-head">Age Minimum</div>
                    <div className="fleet-info-item-desc">
                      {singlevehicleData?.General?.min_age_driver}
                    </div>
                  </Grid>
                  <Grid item md={2} lg={2} xl={2} sm={4} xs={4}>
                    <div className="fleet-info-item-head">Type De License</div>
                    <div className="fleet-info-item-desc">
                      {singlevehicleData?.General?.license_class}
                    </div>
                  </Grid>
                </Grid>
              )}
            </Box>
          </Grid>
        </Grid>
      </Paper>

      <Paper sx={{ boxShadow: "0px 4px 18px 0px #4B465C1A", mt: 3 }}>
        <Grid item sm={12}>
          <Formik
            validationSchema={validationSchema}
            initialValues={generalInfo}
            enableReinitialize={true}
            onSubmit={async (values) => {
              const tempData = { ...singlevehicleData, GeneralInfo: values };
              const data = await updateVehicle(id, tempData);
              if (data) {
                setEdit(true);
                dispatch(fetchSingleVehicleList(id));
              }
            }}
          >
            {({ values, errors, handleChange, handleSubmit }) => (
              <Box px={3} pt={2} pb={4}>
                <Box display="flex" justifyContent="space-between">
                  <Typography color="#4B465C" fontSize={18} fontWeight={500}>
                    Information général
                  </Typography>
                  {isEdit && (
                    <IconButton size="small" onClick={() => setEdit(false)}>
                      <img src={EditIcon} alt="Edit" width={24} height={24} />
                    </IconButton>
                  )}
                  {!isEdit && (
                    <button
                      type="submit"
                      className="btn btn-primary waves-effect waves-light"
                      onClick={handleSubmit}
                    >
                      Save
                    </button>
                  )}
                </Box>
                <Box mt={3} />
                <Grid container spacing={3}>
                  <Grid item md={1.7} lg={1.7} xl={1.7} sm={4} xs={4}>
                    <div className="fleet-info-item-head">
                      Plaque D’immatriculation
                    </div>
                    <div className="fleet-info-item-desc">
                      <Field
                        value={values.license_plate}
                        readOnly={isEdit}
                        name="license_plate"
                        type="text"
                        className={` ${
                          isEdit ? "hide-input" : "form-control"
                        } ${errors.license_plate && "dropdown-invalid"}`}
                        placeholder="LU0001"
                      />
                    </div>
                  </Grid>
                  <Grid item md={1.7} lg={1.7} xl={1.7} sm={4} xs={4}>
                    <div className="fleet-info-item-head">
                      Numéro de Chassis
                    </div>
                    <div className="fleet-info-item-desc">
                      <Field
                        readOnly={isEdit}
                        name="chassis_number"
                        value={values.chassis_number}
                        placeholder="Type"
                        className={` ${
                          isEdit ? "hide-input" : "form-control"
                        } ${errors.chassis_number && "dropdown-invalid"}`}
                      />
                    </div>
                  </Grid>
                  <Grid item md={1.7} lg={1.7} xl={1.7} sm={4} xs={4}>
                    <div className="fleet-info-item-head">Motorisation</div>
                    <div className="fleet-info-item-desc">
                      <Dropdown
                        disabled={isEdit}
                        value={values?.main_fuel}
                        onChange={handleChange}
                        options={fuleDropdown}
                        name="main_fuel"
                        optionLabel="name"
                        placeholder="Type"
                        className={`w-full ${
                          isEdit ? "p-dropdown-hide" : ".p-dropdown"
                        } ${errors?.main_fuel && "dropdown-invalid"}`}
                      />
                    </div>
                  </Grid>
                  {/* <Grid item md={1.7} lg={1.7} xl={1.7} sm={4} xs={4}>
                    <div className="fleet-info-item-head">
                      Carburant Secondaire
                    </div>
                    <div className="fleet-info-item-desc">
                      <Dropdown
                        disabled={isEdit}
                        value={values?.second_fuel}
                        onChange={handleChange}
                        options={fuleDropdown}
                        name="second_fuel"
                        optionLabel="name"
                        placeholder="Type"
                        className={`w-full ${
                          isEdit ? "p-dropdown-hide" : "p-dropdown"
                        } ${errors?.second_fuel && "dropdown-invalid"}`}
                      />
                    </div>
                  </Grid> */}
                  <Grid item md={1.7} lg={1.7} xl={1.7} sm={4} xs={4}>
                    <div className="fleet-info-item-head">Transmission</div>
                    <div className="fleet-info-item-desc">
                      <Dropdown
                        disabled={isEdit}
                        value={values?.transmission}
                        onChange={handleChange}
                        name="transmission"
                        options={demo2}
                        placeholder="Électrique"
                        className={`w-full ${
                          isEdit ? "p-dropdown-hide" : "p-dropdown"
                        } ${errors?.transmission && "dropdown-invalid"}`}
                      />
                    </div>
                  </Grid>
                  <Grid item md={1.7} lg={1.7} xl={1.7} sm={4} xs={4}>
                    <div className="fleet-info-item-head">Puissance (Ch)</div>
                    <div className="fleet-info-item-desc">
                      <Field
                        type="number"
                        readOnly={isEdit}
                        name="resume"
                        value={values.resume}
                        placeholder="Type"
                        className={` ${
                          isEdit ? "hide-input" : "form-control"
                        } ${errors.resume && "dropdown-invalid"}`}
                      />
                    </div>
                  </Grid>
                  <Grid item md={1.7} lg={1.7} xl={1.7} sm={4} xs={4}>
                    <div className="fleet-info-item-head">Réservoir</div>
                    <div className="fleet-info-item-desc">
                      <Field
                        readOnly={isEdit}
                        value={values.tank_size}
                        name="tank_size"
                        type="number"
                        className={` ${
                          isEdit ? "hide-input" : "form-control"
                        } ${errors.tank_size && "dropdown-invalid"}`}
                        placeholder="L"
                        aria-controls="DataTables_Table_0"
                      />
                    </div>
                  </Grid>
                  <Grid item md={1.7} lg={1.7} xl={1.7} sm={4} xs={4}>
                    <div className="fleet-info-item-head">CO2</div>
                    <div className="fleet-info-item-desc">
                      <Field
                        readOnly={isEdit}
                        name="co2_emissions"
                        value={values.co2_emissions}
                        placeholder="CO2"
                        className={` ${
                          isEdit ? "hide-input" : "form-control"
                        } ${errors.co2_emissions && "dropdown-invalid"}`}
                      />
                    </div>
                  </Grid>
                  <Grid item md={1.7} lg={1.7} xl={1.7} sm={4} xs={4}>
                    <div className="fleet-info-item-head">K.W.</div>
                    <div className="fleet-info-item-desc">
                      <Field
                        type="number"
                        readOnly={isEdit}
                        name="k_w"
                        value={values.k_w}
                        placeholder="Type"
                        className={` ${
                          isEdit ? "hide-input" : "form-control"
                        } ${errors.k_w && "dropdown-invalid"}`}
                      />
                    </div>
                  </Grid>
                  <Grid item md={1.7} lg={1.7} xl={1.7} sm={4} xs={4}>
                    <div className="fleet-info-item-head">Cylindrée</div>
                    <div className="fleet-info-item-desc">
                      <Field
                        readOnly={isEdit}
                        value={values.displacement}
                        name="displacement"
                        type="number"
                        className={` ${
                          isEdit ? "hide-input" : "form-control"
                        } ${errors.displacement && "dropdown-invalid"}`}
                        placeholder="cylindré"
                        aria-controls="DataTables_Table_0"
                      />
                    </div>
                  </Grid>
                  <Grid item md={1.7} lg={1.7} xl={1.7} sm={4} xs={4}>
                    <div className="fleet-info-item-head">MMA</div>
                    <div className="fleet-info-item-desc">
                      <Field
                        readOnly={isEdit}
                        value={values.good}
                        name="good"
                        type="text"
                        className={` ${
                          isEdit ? "hide-input " : "form-control"
                        } ${errors.good && "dropdown-invalid"}`}
                        placeholder="MMA"
                        aria-controls="DataTables_Table_0"
                      />
                    </div>
                  </Grid>
                  <Grid item md={1.7} lg={1.7} xl={1.7} sm={4} xs={4}>
                    <div className="fleet-info-item-head">Chevaux Fiscaux</div>
                    <div className="fleet-info-item-desc">
                      <Field
                        type="number"
                        readOnly={isEdit}
                        name="tax_power"
                        value={values.tax_power}
                        placeholder="Type"
                        className={` ${
                          isEdit ? "hide-input" : "form-control"
                        } ${errors.tax_power && "dropdown-invalid"}`}
                      />
                    </div>
                  </Grid>
                  <Grid item md={1.7} lg={1.7} xl={1.7} sm={4} xs={4}>
                    <div className="fleet-info-item-head">Nombre de Sièges</div>
                    <div className="fleet-info-item-desc">
                      <Field
                        readOnly={isEdit}
                        value={values.seat_name}
                        name="seat_name"
                        type="text"
                        className={` ${
                          isEdit ? "hide-input" : "form-control"
                        } ${errors.seat_name && "dropdown-invalid"}`}
                        placeholder="0"
                        aria-controls="DataTables_Table_0"
                      />
                    </div>
                  </Grid>
                  <Grid item md={1.7} lg={1.7} xl={1.7} sm={4} xs={4}>
                    <div className="fleet-info-item-head">Nombre de Portes</div>
                    <div className="fleet-info-item-desc">
                      <Field
                        readOnly={isEdit}
                        value={values.no_doors}
                        name="no_doors"
                        type="number"
                        className={` ${
                          isEdit ? "hide-input" : "form-control"
                        } ${errors.no_doors && "dropdown-invalid"}`}
                        placeholder="0"
                        aria-controls="DataTables_Table_0"
                      />
                    </div>
                  </Grid>
                </Grid>
              </Box>
            )}
          </Formik>
        </Grid>
      </Paper>

      <Paper sx={{ boxShadow: "0px 4px 18px 0px #4B465C1A", mt: 3 }}>
        <Grid item sm={12}>
          <Formik
            validationSchema={validationSchema2}
            initialValues={insuranceInfo}
            enableReinitialize={true}
            onSubmit={async (values) => {
              const tempData = { ...singlevehicleData, InsuranceInfo: values };
              const data = await updateVehicle(id, tempData);
              if (data) {
                setEdit2(true);
              }
            }}
          >
            {({ values, errors, handleChange, handleSubmit }) => (
              <Box px={3} pt={2} pb={4}>
                <Box display="flex" justifyContent="space-between">
                  <Typography color="#4B465C" fontSize={18} fontWeight={500}>
                    Information D’assurances
                  </Typography>
                  {isEdit2 && (
                    <IconButton size="small" onClick={() => setEdit2(false)}>
                      <img src={EditIcon} alt="Edit" width={24} height={24} />
                    </IconButton>
                  )}
                  {!isEdit2 && (
                    <button
                      type="submit"
                      className="btn btn-primary waves-effect waves-light"
                      onClick={handleSubmit}
                    >
                      Save
                    </button>
                  )}
                </Box>
                <Box mt={3} />
                <Grid container spacing={3}>
                  <Grid item md={1.7} lg={1.7} xl={1.7} sm={4} xs={4}>
                    <div className="fleet-info-item-head">
                      Courtier D’assurance
                    </div>
                    <div className="fleet-info-item-desc">
                      <Field
                        readOnly={isEdit2}
                        name="issuer"
                        value={values.issuer}
                        placeholder="Type"
                        className={` ${
                          isEdit2 ? "hide-input" : "form-control"
                        } ${errors.issuer && "dropdown-invalid"}`}
                      />
                    </div>
                  </Grid>
                  <Grid item md={1.7} lg={1.7} xl={1.7} sm={4} xs={4}>
                    <div className="fleet-info-item-head">
                      Debut De L’assurance
                    </div>
                    <div className="fleet-info-item-desc">
                      <Field
                        type="date"
                        readOnly={isEdit2}
                        name="date_issue"
                        defaultValue={values.date_issue}
                        placeholder="Type"
                        className={` ${
                          isEdit2 ? "hide-input" : "form-control"
                        } ${errors.date_issue && "dropdown-invalid"}`}
                      />
                    </div>
                  </Grid>
                  <Grid item md={1.7} lg={1.7} xl={1.7} sm={4} xs={4}>
                    <div className="fleet-info-item-head">
                      Contact Assistance
                    </div>
                    <div className="fleet-info-item-desc">
                      <Field
                        readOnly={isEdit2}
                        name="support_contact"
                        value={values.support_contact}
                        placeholder="Type"
                        className={`${isEdit2 ? "hide-input" : "form-control"}`}
                      />
                    </div>
                  </Grid>
                  <Grid item md={1.7} lg={1.7} xl={1.7} sm={4} xs={4}>
                    <div className="fleet-info-item-head">Franchise Vol</div>
                    <div className="fleet-info-item-desc">
                      <Field
                        readOnly={isEdit2}
                        name="flight_franchise"
                        value={values.flight_franchise}
                        placeholder="Type"
                        className={` ${
                          isEdit2 ? "hide-input" : "form-control"
                        }`}
                      />
                    </div>
                  </Grid>
                  <Grid item md={1.7} lg={1.7} xl={1.7} sm={4} xs={4}>
                    <div className="fleet-info-item-head">Franchise Crash</div>
                    <div className="fleet-info-item-desc">
                      <Field
                        readOnly={isEdit2}
                        name="franchise_crash"
                        value={values.franchise_crash}
                        placeholder="Type"
                        className={` ${
                          isEdit2 ? "hide-input" : "form-control"
                        }`}
                      />
                    </div>
                  </Grid>
                  <Grid item md={1.7} lg={1.7} xl={1.7} sm={4} xs={4}>
                    <div className="fleet-info-item-head">
                      Franchise Perte Totale
                    </div>
                    <div className="fleet-info-item-desc">
                      <Field
                        type="number"
                        readOnly={isEdit2}
                        name="total_claims"
                        value={values.total_claims}
                        placeholder="Type"
                        className={` ${
                          isEdit2 ? "hide-input" : "form-control"
                        }`}
                      />
                    </div>
                  </Grid>
                  <Grid item md={1.7} lg={1.7} xl={1.7} sm={4} xs={4}>
                    <div className="fleet-info-item-head">Client Memo</div>
                    <div className="fleet-info-item-desc">
                      <Field
                        readOnly={isEdit2}
                        name="info"
                        value={values.info}
                        placeholder="Type"
                        className={`${isEdit2 ? "hide-input" : "form-control"}`}
                      />
                    </div>
                  </Grid>
                </Grid>
              </Box>
            )}
          </Formik>
        </Grid>
      </Paper>

      {isColorModalShow && (
        <ColorModal
          show={isColorModalShow}
          onHide={() => setColorModalShow(false)}
        />
      )}
    </Box>
  );
};

export default FleetGeneral;
