import { useEffect, useState } from "react";
import moment from "moment";

const useGenerateHeadersAndOverlays = ({
  monthsOfYear,
  daysOfYear,
  weeksOfYear,
  cellWidth,
  viewType,
}) => {
  const [headers, setHeaders] = useState([]);
  const [overlays, setOverlays] = useState([]);

  useEffect(() => {
    setHeaders([]);
    setOverlays([]);
  }, [viewType]);
  useEffect(() => {
    const newHeaders = [];
    const newOverlays = [];

    const renderHeaderCell = (key, content, customStyle = {}) => (
      <div
        key={key}
        className="header-cell"
        id={key}
        style={{
          whiteSpace: "nowrap",
          width: `${cellWidth}px`,
          textAlign: "center",
          ...customStyle,
        }}
      >
        {content}
      </div>
    );

    const renderOverlay = (key, left, width, label) => (
      <div
        key={key}
        className="month-overlay"
        style={{
          position: "absolute",
          left: `${left}px`,
          width: `${width}px`,
          textAlign: "center",
          fontSize: "14px",
          fontWeight: "bold",
          zIndex: 99,
          height: "30px",
          lineHeight: "30px",
          marginTop: "60px",
        }}
      >
        {label}
      </div>
    );

    if (viewType === "day") {
      monthsOfYear.forEach((month) => {
        const startOfMonth = moment(month.start);
        const endOfMonth = moment(month.end);

        const startIndex = daysOfYear.findIndex((day) =>
          moment(day).isSame(startOfMonth, "day")
        );
        const endIndex = daysOfYear.findIndex((day) =>
          moment(day).isSame(endOfMonth, "day")
        );

        const overlayWidth = (endIndex - startIndex + 1) * cellWidth;
        const overlayLeft = startIndex * cellWidth;

        newOverlays.push(
          renderOverlay(
            month.start.toISOString(),
            overlayLeft,
            overlayWidth,
            startOfMonth.format("MMMM YYYY")
          )
        );
      });

      daysOfYear.forEach((day, index) => {
        const formattedDate = moment(day).format("YYYY-MM-DD");
        newHeaders.push(
          renderHeaderCell(
            formattedDate,
            <>
              <div
                style={{ fontWeight: "700", color: "#121D22" }}
                className="d-1"
              >
                {moment(day).format("D")}
              </div>
              <div
                style={{ fontSize: "400", marginTop: "4px", color: "#9D9CA3" }}
                className="d-2"
              >
                {moment(day).format("ddd")}
              </div>
            </>
          )
        );
      });
    } else if (viewType === "week") {
      moment.locale("fr");

      monthsOfYear.forEach((month) => {
        const startOfMonth = moment(month.start);
        const endOfMonth = moment(month.end);

        const firstWeekIndex = weeksOfYear.findIndex(
          (week) =>
            moment(week.start).isSameOrAfter(startOfMonth, "week") &&
            moment(week.end).isSameOrBefore(endOfMonth, "week")
        );

        const lastWeekIndex = weeksOfYear.findIndex(
          (week) =>
            moment(week.end).isSameOrBefore(endOfMonth, "week") &&
            moment(week.start).isSameOrAfter(startOfMonth, "week")
        );

        if (firstWeekIndex !== -1 && lastWeekIndex !== -1) {
          const overlayWidth = (lastWeekIndex - firstWeekIndex + 1) * cellWidth;
          const overlayLeft = firstWeekIndex * cellWidth;

          newOverlays.push(
            renderOverlay(
              month.start.toISOString(),
              overlayLeft,
              overlayWidth,
              startOfMonth.format("MMMM YYYY")
            )
          );
        }
      });

      weeksOfYear.forEach((week, index) => {
        const startOfWeek = moment(week.end);
        const weekNumberInYear = startOfWeek.week();
        const key = `week-${weekNumberInYear}-${startOfWeek.format("YYYY")}`;
        newHeaders.push(
          renderHeaderCell(
            key,
            <>
              <span
                style={{
                  fontWeight: "400",
                  color: "#7A7C7E",
                  marginRight: "1px",
                }}
                className="d-1"
              >
                Sem.
              </span>
              <span
                style={{ fontWeight: "700", color: "#000000" }}
                className="d-2"
              >
                {weekNumberInYear}
              </span>
            </>
          )
        );
      });
    } else if (viewType === "month") {
      monthsOfYear.forEach((month, index) => {
        const startOfMonth = moment(month.start);
        const key = `month-${startOfMonth.month() + 1}-${startOfMonth.format(
          "YYYY"
        )}`;
        newHeaders.push(
          renderHeaderCell(
            key,
            <>
              <span
                style={{
                  fontWeight: "400",
                  color: "#000000",
                  marginRight: "4px",
                }}
                className="d-1"
              >
                {startOfMonth.format("MMM")}
              </span>
              <span
                style={{
                  fontWeight: "400",
                  color: "#9D9CA3",
                  fontSize: "smaller",
                }}
                className="d-2"
              >
                {startOfMonth.format("YYYY")}
              </span>
            </>
          )
        );
      });
    }

    setTimeout(() => {
      setHeaders(newHeaders);
      setOverlays(newOverlays);
    }, 500);
  }, [viewType, monthsOfYear, daysOfYear, weeksOfYear, cellWidth]);

  return { headers, overlays };
};

export { useGenerateHeadersAndOverlays };
