import React, { useEffect, useState } from "react";
import axios from "axios";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import {
  getVisibiliteeCars,
  updateHiddenSetting,
  updatePositions,
  updatePreference,
} from "../../services/visibilitee";
import { ReactComponent as DragDrop } from "../../assets/icons/drag-drop.svg";
import { ReactComponent as Resize } from "../../assets/icons/resize.svg";
import {
  Autocomplete,
  Box,
  CircularProgress,
  InputBase,
  Paper,
  Tooltip,
} from "@mui/material";
import { Dropdown } from "primereact/dropdown";
import { toast } from "react-toastify";
import { BsXLg } from "react-icons/bs";
import { MdOutlineDragIndicator } from "react-icons/md";

const VisibiliteePage = () => {
  const [cars, setCars] = useState({
    multi: [],
    single: [],
    nonVisible: [],
  });
  const [expanded, setExpanded] = useState("");
  const [reload, setReload] = useState(false);
  const [hiddingId, setHiddingId] = useState("");
  const [selectedCar, setSelectedCar] = useState({ multi: [], single: [] });

  // Fetch cars from the backend on component mount
  useEffect(() => {
    const fetchCars = async () => {
      try {
        const response = await getVisibiliteeCars();
        console.log(response);
        setCars(response.data);
      } catch (error) {
        console.error("Failed to fetch cars:", error);
      }
    };

    fetchCars();
  }, [reload]);

  // Handle drag end
  const onDragEnd = async (result, section) => {
    const { source, destination } = result;

    // Do nothing if the item is dropped outside the list
    if (!destination) return;

    // Do nothing if the item is dropped in the same position
    if (source.index === destination.index) return;

    // Rearrange the cars within the section
    const reorderedCars = Array.from(cars[section]);
    const [movedCar] = reorderedCars.splice(source.index, 1);
    reorderedCars.splice(destination.index, 0, movedCar);

    // Update the state with reordered cars
    setCars((prevCars) => ({
      ...prevCars,
      [section]: reorderedCars,
    }));

    // Update the backend with the new sequence
    try {
      const finalArray = [];

      reorderedCars.forEach((c, index) => {
        finalArray.push({
          _id: c?.general?._id,
          sequence: index,
          section: result?.source?.droppableId,
        });
        if (c.colors.length > 1) {
          c.colors.forEach((cc) => {
            const generalId = c.color_d?.find((f) => f.color === cc.name);
            if (generalId?.general?._id !== c?.general?._id) {
              finalArray.push({
                _id: generalId?.general?._id,
                sequence: index,
                section: result?.source?.droppableId,
              });
            }
          });
        }
      });
      await updatePositions({
        cars: finalArray,
      });
      toast.success("Sequence updated successfully");
    } catch (error) {
      console.error("Failed to update sequence:", error);
    }
  };

  const updateColorPreference = async (value, car) => {
    try {
      const generalId = car.color_d?.find((f) => f.color === value.name);
      await updatePreference({
        car: generalId?.general?._id,
        otherCars: car.color_d
          .filter((f) => f.general?._id !== generalId?.general?._id)
          .map((r) => r.general?._id),
      });
      setReload(!reload);
      toast.success("Color preference updated successfully");
    } catch {}
  };

  const hasAnyItemWithModelAndManufacturer = (section, manufacturer, model) => {
    const items = cars[section] || [];
    return items?.filter(
      (r) => r?.manufacturer?._id === manufacturer && r?.model?._id === model
    )?.length > 1
      ? true
      : false;
  };

  const hideCar = async (car, sectionKey) => {
    const generalId = car?.general?._id;
    setHiddingId(`${sectionKey}_${generalId}`);

    try {
      await updateHiddenSetting({
        cars: [{ _id: car?.general?._id, section: sectionKey }],
        isHidden: true,
      });
      setHiddingId("");
      setReload(!reload);
    } catch {
      toast.error("Something went wrong.");
    }
  };

  const visibleCar = async (cars, sectionKey) => {
    // const generalId = car?.general?._id;
    // setHiddingId(`${sectionKey}_${generalId}`);

    try {
      await updateHiddenSetting({
        cars: cars.map((car) => ({
          _id: car?.general?._id,
          section: sectionKey,
        })),
        isHidden: false,
      });
      setHiddingId("");
      setReload(!reload);
      setSelectedCar({ ...selectedCar, [sectionKey]: [] });
    } catch {
      toast.error("Something went wrong.");
    }
  };

  // Render the cars in a section
  const renderCarSection = (sectionTitle, sectionKey) => (
    <Droppable
      droppableId={sectionKey}
      key={sectionKey}
      isDropDisabled={sectionKey === "nonVisible"}
    >
      {(provided) => (
        <div
          className="car-section"
          {...provided.droppableProps}
          ref={provided.innerRef}
        >
          <div className="d-flex justify-content-between align-items-center mb-3">
            <h3 className="m-0">
              {sectionTitle} ({cars[sectionKey].length})
            </h3>
            <button
              className="btn p-0"
              onClick={() =>
                setExpanded((prev) => (prev === sectionKey ? "" : sectionKey))
              }
            >
              <Resize />
            </button>
          </div>
          <div className="cars-list">
            {cars[sectionKey].map((car, index) => (
              <Draggable
                key={`${car?.general_info?._id}-${sectionKey}`}
                draggableId={`${car?.general_info?._id}-${sectionKey}`}
                index={index}
              >
                {(provided) => (
                  <div
                    className="car-item"
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                  >
                    <div className="d-flex align-items-center gap-3 w-full">
                      {/* {car?.color && car?.colors?.length > 0 && (
                        <div className="car-colors-container">
                          {car?.colors?.map((c) => (
                            <div
                              className={`car-color-ico ${
                                c?.name === car?.color?.name ? `selected` : ``
                              }`}
                              onClick={(e) =>
                                updateColorPreference(c?.name, car)
                              }
                            >
                              <Tooltip title={c?.name}>
                                <img
                                  src={`${process.env.REACT_APP_IMG_URL}${c?.image}`}
                                  alt=""
                                />
                              </Tooltip>
                            </div>
                          ))}
                        </div>
                      )} */}
                      <MdOutlineDragIndicator />
                      <div className={`car-color-ico`}>
                        <Tooltip title={car?.color?.name}>
                          <img
                            src={`${process.env.REACT_APP_IMG_URL}${car?.color?.image}`}
                            alt=""
                          />
                        </Tooltip>
                      </div>
                      {car?.manufacturer?.name} {car?.model?.name}{" "}
                      {car?.general?.declination}
                      {/* {car?.colors?.length > 0 && (
                        <Dropdown
                          className="car-item m-0"
                          options={car?.colors}
                          optionLabel="name"
                          value={car?.color}
                          onChange={(e) => updateColorPreference(e.value, car)}
                        />
                      )} */}
                    </div>
                    {hasAnyItemWithModelAndManufacturer(
                      sectionKey,
                      car?.manufacturer?._id,
                      car?.model?._id
                    ) &&
                      sectionKey !== "nonVisible" && (
                        <>
                          {hiddingId !==
                          `${sectionKey}_${car?.general?._id}` ? (
                            <BsXLg
                              className="cursor-pointer"
                              onClick={() =>
                                !hiddingId && hideCar(car, sectionKey)
                              }
                            />
                          ) : (
                            <CircularProgress size={20} />
                          )}
                        </>
                      )}
                    {/* <DragDrop /> */}
                  </div>
                )}
              </Draggable>
            ))}
          </div>

          {sectionKey !== "nonVisible" && (
            <div className="d-flex flex-column gap-2">
              <Autocomplete
                multiple
                id="vehicle-selection-filled"
                options={cars?.[`${sectionKey}_hidden`] || []} // Use grouped vehicles as options
                getOptionLabel={(option) =>
                  `${option?.manufacturer?.name?.toString()} ${option?.model?.name?.toString()} ${option?.general?.declination?.toString()}`
                }
                onChange={(e, newValue) => {
                  setSelectedCar({ ...selectedCar, [sectionKey]: newValue });
                }} // Update vehicle selection in Formik
                value={selectedCar?.[sectionKey] || []} // Use Formik's value for vehicle selection
                sx={{
                  "& .MuiAutocomplete-inputRoot": {
                    paddingRight: "0px !important", // Removes right-side padding
                  },
                  "& .MuiAutocomplete-popupIndicator": {
                    display: "none", // Hides the dropdown icon
                  },
                  "& .MuiChip-label": {
                    padding: 0,
                  },
                }}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => {
                    const { key } = getTagProps({ index });
                    return (
                      <Box
                        variant="outlined"
                        label={`${option?.manufacturer?.name?.toString()} ${option?.model?.name?.toString()} ${option?.general?.declination?.toString()}`}
                        key={key || option.title}
                        sx={{
                          color: "#000000",
                          borderRadius: "10px",
                          width: "100%",
                          display: "flex",
                          justifyContent: "space-between",
                          border: "none",
                          fontSize: 12,
                          textAlign: "left",
                          py: 0.5,
                          gap: 0.5,
                          flexWrap: "no-wrap",
                        }}
                      >
                        {`${option?.manufacturer?.name?.toString()} ${option?.model?.name?.toString()} ${option?.general?.declination?.toString()}`}
                        {/* <Box display="flex" gap={0.5} alignItems="center">
                          {option?.colors?.length > 0 && (
                            <Dropdown
                              options={option?.colors}
                              optionLabel="name"
                              optionValue="name"
                              value={
                                selectedCar?.[sectionKey]?.[index]?.color ||
                                null
                              } // Show selected color if available
                              onChange={(e) => {
                                // Update the color in the selected vehicle
                                const updatedSelection = [
                                  ...selectedCar?.[sectionKey],
                                ];
                                updatedSelection[index] = {
                                  ...updatedSelection[index],
                                  color: e.value, // Ensure the color is properly updated
                                };
                                setSelectedCar({
                                  ...selectedCar,
                                  [sectionKey]: updatedSelection,
                                });
                              }}
                              style={{
                                height: 30,
                                maxWidth: "150px",
                                padding: 0,
                              }}
                            />
                          )}
                        </Box> */}
                      </Box>
                    );
                  })
                }
                clearIcon={false}
                popupIcon={null}
                renderInput={(params) => (
                  <Paper
                    component="div"
                    elevation={0}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: "4px 8px",
                      border: "0px solid #A8A8A8",
                      borderRadius: "10px",
                    }}
                  >
                    <InputBase
                      {...params.InputProps}
                      inputProps={params.inputProps}
                      style={{ flex: 1 }}
                      placeholder="+ Ajouter un vehicule"
                    />
                  </Paper>
                )}
              />
              {selectedCar?.[sectionKey]?.length > 0 && (
                <div className="d-flex gap-2">
                  <button
                    className="btn btn-primary-light"
                    onClick={() =>
                      setSelectedCar({ ...selectedCar, [sectionKey]: [] })
                    }
                  >
                    Annuler
                  </button>
                  <button
                    className="btn btn-primary"
                    onClick={() =>
                      visibleCar(selectedCar[sectionKey], sectionKey)
                    }
                  >
                    Valider
                  </button>
                </div>
              )}
            </div>
          )}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );

  return (
    <div className="car-sequence">
      <DragDropContext
        onDragEnd={(result) => onDragEnd(result, result.source.droppableId)}
      >
        <div className="row gap-4" style={{ position: "relative" }}>
          <div
            className={`company-item-card  ${
              expanded === "multi" ? "full-section" : "col-3"
            }`}
          >
            {renderCarSection("Ordre Multi", "multi")}
          </div>
          <div
            className={`company-item-card ${
              expanded === "single" ? "full-section" : "col-3"
            }`}
          >
            {renderCarSection("Ordre Single", "single")}
          </div>
          {/* <div
            className={`company-item-card ${
              expanded === "nonVisible" ? "full-section" : "col-3"
            }`}
          >
            {renderCarSection("Non Visible", "nonVisible")}
          </div> */}
        </div>
      </DragDropContext>
    </div>
  );
};

export default VisibiliteePage;
