import "../css/CalendarStyles.css";

import { useReducer, useRef, useState } from "react";
import { FaCalendar } from "react-icons/fa";
import { useGenerateHeadersAndOverlays } from "../services/Calendar/helpers";
import { useEffect, useCallback } from "react";
import moment from "moment";
import ViewTypes from "../components/Calendar/ViewTypes";
import CarsList from "../components/Calendar/CarsList";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import FilterSidebar from "../components/Calendar/FilterSidebar";
import { toast } from "react-toastify";
import { MdDragIndicator } from "react-icons/md";
import { reservationList } from "../Redux/calanderSlice";
import {
  deleteReservationById,
  updateResrvation,
} from "../services/Calendar/reservation";
import { fetchVehicleList } from "../Redux/vehicleSlice";
import AddReservation from "../components/Calendar/AddReservation";
import HoveredReservation from "../components/Calendar/HoveredReservation";

moment.locale("fr");
moment.updateLocale("fr", {
  months: [
    "Janvier",
    "Février",
    "Mars",
    "Avril",
    "Mai",
    "Juin",
    "Juillet",
    "Août",
    "Septembre",
    "Octobre",
    "Novembre",
    "Décembre",
  ],
});

// Action types for the reducer
const ActionTypes = {
  SET_DAYS: "SET_DAYS",
  SET_WEEKS: "SET_WEEKS",
  SET_MONTHS: "SET_MONTHS",
  SET_VISIBLE_RANGE: "SET_VISIBLE_RANGE",
};

const initialState = {
  daysOfYear: [],
  weeksOfYear: [],
  monthsOfYear: [],
  visibleRange: {
    day: { start: moment.utc(), end: moment.utc() },
    week: { start: moment.utc(), end: moment.utc() },
    month: { start: moment.utc(), end: moment.utc() },
  },
};

// Reducer to manage the state
const calendarReducer = (state, action) => {
  switch (action.type) {
    case ActionTypes.SET_DAYS:
      return { ...state, daysOfYear: action.payload };
    case ActionTypes.SET_WEEKS:
      return { ...state, weeksOfYear: action.payload };
    case ActionTypes.SET_MONTHS:
      return { ...state, monthsOfYear: action.payload };
    case ActionTypes.SET_VISIBLE_RANGE:
      return { ...state, visibleRange: action.payload };
    default:
      return state;
  }
};

const useCalendarChunks = ({
  dayRange = 25,
  weekRange = 2,
  monthRange = 2,
}) => {
  const [state, dispatch] = useReducer(calendarReducer, initialState);

  const generateDays = useCallback((start, end) => {
    const days = [];
    let current = start.clone();
    while (current.isBefore(end, "day")) {
      days.push(current.clone());
      current.add(1, "day");
    }
    return days;
  }, []);

  const generateWeeks = useCallback((start, end) => {
    const weeks = [];
    let current = start.clone().startOf("week");
    while (current.isBefore(end, "day")) {
      weeks.push({
        start: current.clone(),
        end: current.clone().add(6, "days"),
      });
      current.add(1, "week");
    }
    return weeks;
  }, []);

  const generateMonths = useCallback((start, end) => {
    const months = [];
    let current = start.clone().startOf("month");
    while (current.isBefore(end, "month")) {
      months.push({
        start: current.clone(),
        end: current.clone().endOf("month"),
        name: current.format("MMMM YYYY"),
      });
      current.add(1, "month");
    }
    return months;
  }, []);

  const initializeChunks = useCallback(() => {
    const now = moment.utc();
    const dayStart = now.clone().subtract(dayRange, "days");
    const dayEnd = now.clone().add(dayRange, "days");
    const weekStart = now.clone().subtract(weekRange, "years");
    const weekEnd = now.clone().add(weekRange, "years");
    const monthStart = now.clone().subtract(monthRange, "years");
    const monthEnd = now.clone().add(monthRange, "years");

    const days = generateDays(dayStart, dayEnd);
    const weeks = generateWeeks(weekStart, weekEnd);
    const months = generateMonths(monthStart, monthEnd);

    const visibleRange = {
      day: { start: dayStart, end: dayEnd },
      week: { start: weekStart, end: weekEnd },
      month: { start: monthStart, end: monthEnd },
    };

    dispatch({ type: ActionTypes.SET_DAYS, payload: days });
    dispatch({ type: ActionTypes.SET_WEEKS, payload: weeks });
    dispatch({ type: ActionTypes.SET_MONTHS, payload: months });
    dispatch({ type: ActionTypes.SET_VISIBLE_RANGE, payload: visibleRange });
  }, [
    dayRange,
    weekRange,
    monthRange,
    generateDays,
    generateWeeks,
    generateMonths,
  ]);

  const appendChunks = useCallback(
    (direction, viewType) => {
      const { start, end } = state.visibleRange[viewType];
      let newStart, newEnd;

      if (direction === "left") {
        if (viewType === "day") {
          newStart = start.clone().subtract(dayRange, "days");
          const newDays = generateDays(newStart, start);
          dispatch({
            type: ActionTypes.SET_DAYS,
            payload: [...newDays, ...state.daysOfYear],
          });
        } else if (viewType === "week") {
          newStart = start.clone().subtract(weekRange, "years");
          const newWeeks = generateWeeks(newStart, start);
          dispatch({
            type: ActionTypes.SET_WEEKS,
            payload: [...newWeeks, ...state.weeksOfYear],
          });
        } else if (viewType === "month") {
          newStart = start.clone().subtract(monthRange, "years");
          const newMonths = generateMonths(newStart, start);
          dispatch({
            type: ActionTypes.SET_MONTHS,
            payload: [...newMonths, ...state.monthsOfYear],
          });
        }
        dispatch({
          type: ActionTypes.SET_VISIBLE_RANGE,
          payload: {
            ...state.visibleRange,
            [viewType]: { start: newStart, end },
          },
        });
      } else if (direction === "right") {
        if (viewType === "day") {
          newEnd = end.clone().add(dayRange, "days");
          const newDays = generateDays(end, newEnd);
          dispatch({
            type: ActionTypes.SET_DAYS,
            payload: [...state.daysOfYear, ...newDays],
          });
        } else if (viewType === "week") {
          newEnd = end.clone().add(weekRange, "years");
          const newWeeks = generateWeeks(end, newEnd);
          dispatch({
            type: ActionTypes.SET_WEEKS,
            payload: [...state.weeksOfYear, ...newWeeks],
          });
        } else if (viewType === "month") {
          newEnd = end.clone().add(monthRange, "years");
          const newMonths = generateMonths(end, newEnd);
          dispatch({
            type: ActionTypes.SET_MONTHS,
            payload: [...state.monthsOfYear, ...newMonths],
          });
        }
        dispatch({
          type: ActionTypes.SET_VISIBLE_RANGE,
          payload: {
            ...state.visibleRange,
            [viewType]: { start, end: newEnd },
          },
        });
      }
    },
    [
      state,
      generateDays,
      generateWeeks,
      generateMonths,
      dayRange,
      weekRange,
      monthRange,
    ]
  );

  useEffect(() => {
    initializeChunks();
  }, [initializeChunks]);

  return {
    daysOfYear: state.daysOfYear,
    weeksOfYear: state.weeksOfYear,
    monthsOfYear: state.monthsOfYear,
    appendChunks,
  };
};

const CalendarNew = () => {
  const cellWidth = 80;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { vehicleList } = useSelector((state) => state?.vehicleSlice);
  const { reservationData } = useSelector((state) => state?.calanderSlice);

  const [id, setId] = useState(null);
  const [activeFilter, setActiveFilter] = useState("");
  const [show, setShow] = useState(false);
  const [viewType, setViewType] = useState("day");
  const [searchTerm, setSearchTerm] = useState("");
  const [appliedFilters, setAppliedFilters] = useState({
    type: [],
    client: [],
    category: [],
    status: [],
    company: [],
  });
  const [showFilters, setShowFilters] = useState(false);
  const [vehicleDataLoc, setVehicleDataLoc] = useState([]);
  const [reservationDataLoc, setReservationDataLoc] = useState([]);
  const [resDataForLoc, setResDataForLoc] = useState(null);

  let defaultTheme = localStorage.getItem("app-theme");

  if (defaultTheme === "system") {
    defaultTheme = window.matchMedia("(prefers-color-scheme: dark)").matches
      ? "dark"
      : "light";
  }

  const [theme, setTheme] = useState(defaultTheme);

  const [finalNewResDates, setFinalNewResDates] = useState({
    start: "",
    end: "",
    vehicle: null,
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [hoveredReservation, setHoveredReservation] = useState(null);
  const [reload, setReload] = useState(false);

  const { daysOfYear, weeksOfYear, monthsOfYear, appendChunks } =
    useCalendarChunks({
      dayRange: 25, // Days to load for day view
      weekRange: 2, // Years to load for week view
      monthRange: 2, // Years to load for month view
    });

  const { headers, overlays } = useGenerateHeadersAndOverlays({
    monthsOfYear,
    weeksOfYear,
    daysOfYear,
    cellWidth,
    viewType,
  });

  const isDragging = useRef(false); // Track dragging state
  const startCell = useRef(null); // Start cell of the selection
  const gridSettled = useRef(false); // Start cell of the selection
  const gridRef = useRef(null);

  const smoothScrollWithCallback = (element, offsetMargin = 100) => {
    if (!element) return; // Ensure the element exists

    // Determine the scrollable container
    const container = element.parentElement; // Update if the scrollable container differs

    if (container) {
      // Find the previous three sibling elements
      const siblings = [];
      let current = element.previousElementSibling;

      while (current && siblings.length < 3) {
        siblings.push(current);
        current = current.previousElementSibling;
      }

      if (siblings.length > 0) {
        // Scroll to the first of the previous three elements
        const targetElement = siblings[siblings.length - 1];

        targetElement.scrollIntoView({
          behavior: "smooth",
          block: "start", // Adjust as needed (e.g., "center" or "start")
          inline: "start", // Horizontal scrolling behavior
        });

        // // Optionally, adjust for the offset
        // setTimeout(() => {
        //   const rect = targetElement.getBoundingClientRect();
        //   const containerRect = container.getBoundingClientRect();

        //   // Calculate the additional scroll needed for the offset
        //   const targetScrollLeft =
        //     container.scrollLeft + rect.left - containerRect.left - offsetMargin;

        //   // Adjust the scroll position
        //   container.scrollTo({
        //     left: targetScrollLeft,
        //     behavior: "smooth",
        //   });
        // }, 500); // Adjust delay based on the duration of your smooth scroll
      }
    }
  };

  const focusCurrent = () => {
    gridSettled.current = false;
    const today = moment(); // Get today's date using moment
    let targetId;

    // Construct target ID based on view type
    if (viewType === "day") {
      targetId = today.format("YYYY-MM-DD"); // Format date as 'YYYY-MM-DD' for day view
    } else if (viewType === "week") {
      // Get the current week number and year
      const currentWeek = moment().week(); // Week number
      const currentYear = moment().year(); // Year
      targetId = `week-${currentWeek}-${currentYear}`;
    } else if (viewType === "month") {
      targetId = `month-${today.month() + 1}-${today.year()}`; // month is 0-based in moment
    }

    console.log(targetId, "targetId");
    // Find the target element and scroll to it
    if (targetId) {
      const targetElement = document.getElementById(targetId);
      if (targetElement) {
        // Add a class for the current date
        targetElement.classList.add("todays-date");
        smoothScrollWithCallback(targetElement);
      }
    }
  };

  const toggleFilters = () => setShowFilters(!showFilters);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleCarImageClick = (general_id) => {
    navigate(`/fleet/general/${general_id}`); // Redirect to the car's page using the car ID
  };

  const resetFilters = () => {
    setAppliedFilters({
      type: [],
      client: [],
      category: [],
      status: [],
      company: [],
    });
  };

  const getCellIndexForReservation = (resv) => {
    const { start_date, return_date } = resv;

    // Parse start and end dates using moment.js
    const startDate = moment(start_date);
    const endDate = moment(return_date);

    let startIdx = -1;
    let endIdx = -1;

    if (viewType === "day") {
      // Find indices for daily view
      startIdx = daysOfYear.findIndex((day) =>
        moment(day).isSame(startDate, "day")
      );
      endIdx = daysOfYear.findIndex((day) =>
        moment(day).isSame(endDate, "day")
      );
    } else if (viewType === "week") {
      // Find indices for weekly view
      startIdx = weeksOfYear.findIndex((week) =>
        startDate.isBetween(week.start, week.end, "day", "[]")
      );
      endIdx = weeksOfYear.findIndex((week) =>
        endDate.isBetween(week.start, week.end, "day", "[]")
      );

      // Handle cases where the reservation spans multiple weeks
      if (startDate.isBefore(weeksOfYear[0].start)) {
        startIdx = 0; // If reservation starts before the first week
      }
      if (endDate.isAfter(weeksOfYear[weeksOfYear.length - 1].end)) {
        endIdx = weeksOfYear.length - 1; // If reservation ends after the last week
      }
    } else if (viewType === "month") {
      // Find indices for monthly view
      startIdx = monthsOfYear.findIndex((month) =>
        startDate.isBetween(month.start, month.end, "day", "[]")
      );
      endIdx = monthsOfYear.findIndex((month) =>
        endDate.isBetween(month.start, month.end, "day", "[]")
      );
    } else {
      console.error(
        "Invalid view type. Supported values are 'day', 'week', and 'month'."
      );
      return { startIdx: -1, endIdx: -1 };
    }

    // Additional error handling for indices
    if (startIdx === -1) {
      console.error("Start date does not match any range in the current view.");
    }
    if (endIdx === -1) {
      console.error("End date does not match any range in the current view.");
    }

    // console.log({ startIdx, endIdx });
    return { startIdx, endIdx };
  };
  const handleDrag = async (
    direction,
    event,
    reservation,
    reservationWidthPx,
    reservationLeftPx,
    startIdx,
    endIdx
  ) => {
    console.log(reservation, direction);
    event.preventDefault();
    const originalX = event.clientX;
    let updatedWidth = reservationWidthPx;
    let updatedLeft = reservationLeftPx;

    const onMouseMove = (moveEvent) => {
      const deltaX = moveEvent.clientX - originalX;
      const deltaCells = Math.round(deltaX / cellWidth) * cellWidth; // Snap to cell width increments

      if (direction === "left") {
        updatedWidth = reservationWidthPx - deltaCells;
        updatedLeft = reservationLeftPx + deltaCells;
      } else if (direction === "right") {
        updatedWidth = reservationWidthPx + deltaCells;
      }

      // Ensure valid dimensions
      if (updatedWidth >= cellWidth) {
        const bar = document.getElementById(`reservation-${reservation._id}`);
        if (bar) {
          bar.style.width = `${updatedWidth}px`;
          bar.style.left = `${updatedLeft}px`;
        }
      }
    };

    const onMouseUp = async () => {
      document.removeEventListener("mousemove", onMouseMove);
      document.removeEventListener("mouseup", onMouseUp);

      // Calculate the new start and end cell indices
      const newStartIdx =
        direction === "left" ? Math.round(updatedLeft / cellWidth) : startIdx;
      const newEndIdx =
        direction === "right"
          ? Math.round(updatedWidth / cellWidth + startIdx - 1)
          : endIdx;

      const newStartDate = daysOfYear[newStartIdx];
      const newEndDate = daysOfYear[newEndIdx];

      // API call to update the reservation
      if (newStartDate && newEndDate) {
        try {
          await updateResrvation(reservation._id, {
            ...reservation,
            start_date: newStartDate.toISOString(),
            return_date: newEndDate.toISOString(),
          });
          dispatch(reservationList());
          console.log(
            `Updated reservation ${reservation._id} to ${newStartDate} - ${newEndDate}`
          );
        } catch (error) {
          console.error("Failed to update reservation:", error);
        }
      }
    };

    document.addEventListener("mousemove", onMouseMove);
    document.addEventListener("mouseup", onMouseUp);
  };

  const getGridRows = () => {
    const renderTodayHighlight = (isToday) =>
      isToday && (
        <span
          style={{
            position: "absolute",
            height: "70px",
            width: "2px",
            background:
              "linear-gradient(90deg,#007aff 0%,#007aff 83.5%,rgba(30, 116, 209, 0) 99.5%)",
            marginLeft: "50px",
          }}
        ></span>
      );

    const renderGridCell = (dayOrWeek, rowIndex, cellIndex, isToday) => (
      <div
        key={`${rowIndex}-${cellIndex}`}
        className="grid-cell"
        onMouseDown={(e) => {
          handleMouseDown(e, rowIndex, cellIndex);
          setFinalNewResDates({
            ...finalNewResDates,
            vehicle: {
              name: `${vehicleDataLoc[rowIndex].manufacturer} ${vehicleDataLoc[rowIndex].model} ${vehicleDataLoc[rowIndex].license_plate}`,
              _id: vehicleDataLoc[rowIndex]?._id,
            },
          });
        }}
        onMouseMove={(e) => handleMouseMove(e, rowIndex, cellIndex)}
        onMouseUp={handleMouseUp}
        id={`cell-${rowIndex}-${cellIndex}`}
        data-id={moment(dayOrWeek).format("YYYY-MM-DD")}
        style={{
          background: isToday ? "#5CAFFF26" : "",
          position: "relative",
        }}
      >
        {renderTodayHighlight(isToday)}
      </div>
    );

    const renderReservation = (reservation, rowIndex) => {
      const { startIdx, endIdx } = getCellIndexForReservation(reservation);

      const reservationWidthPx = (endIdx - startIdx + 1) * cellWidth;
      const reservationLeftPx = startIdx * cellWidth;

      const now = moment();
      const startDate = moment(reservation?.start_date);
      const returnDate = moment(reservation?.return_date);

      let confirmedClass = "";
      let notConfirmedClass = "";

      if (reservation.status === "Confirmed") {
        confirmedClass = "confirmed-resv";
        if (reservation?.reservation_status === "CHECKOUT") {
          confirmedClass = "confirmed-check-out";
        }
        if (reservation?.reservation_status === "DELIVERYOUT") {
          confirmedClass = "confirmed-delivery-out";
        }
        if (reservation?.reservation_status === "DELIVERYIN") {
          confirmedClass = "confirmed-delivery-in";
        }
        if (reservation?.reservation_status === "CHECKIN") {
          confirmedClass = "confirmed-check-in";
        }
        if (
          ![
            "DELIVERYIN",
            "DELIVERYOUT",
            "ADDRESSVALID",
            "ADDRESSNOTVALID",
            "CHECKIN",
            "CHECKOUT",
          ].includes(reservation?.reservation_status)
        ) {
          confirmedClass = "confirmed-archived";
        }
        // if (startDate.isAfter(now)) confirmedClass = "confirmed-future";
        // else if (startDate.isBefore(now) && returnDate.isAfter(now))
        //   confirmedClass = "confirmed-ongoing";
        // else if (returnDate.isBefore(now))
        //   confirmedClass = "confirmed-pending-checkin";
      }

      if (reservation.status === "Not Confirmed") {
        notConfirmedClass = "not-confirmed";
      }

      return (
        <div
          key={`${reservation.car}-${rowIndex}`}
          id={`reservation-${reservation._id}`}
          className={`reservation-bar ${confirmedClass} ${notConfirmedClass}`}
          style={{
            left: `${reservationLeftPx}px`,
            width: `${reservationWidthPx}px`,
            top: "5px",
            padding:
              viewType !== "day"
                ? "3px 20px"
                : confirmedClass === "confirmed-ongoing"
                ? "3px 0px"
                : "3px 5px",
          }}
        >
          {viewType === "day" && (
            <div
              className="drag-handle-left"
              onMouseDown={(event) =>
                handleDrag(
                  "left",
                  event,
                  reservation,
                  reservationWidthPx,
                  reservationLeftPx,
                  startIdx,
                  endIdx
                )
              }
            >
              <MdDragIndicator />
            </div>
          )}
          <div
            className="d-flex flex-row justify-content-between details-bar w-100  reservation-info-data"
            onMouseEnter={(e) =>
              setHoveredReservation({
                reservation,
                position: { x: e.pageX, y: e.pageY },
              })
            }
            onMouseLeave={() => setHoveredReservation(null)}
          >
            <div
              className="d-flex flex-column"
              onClick={() => {
                setShow(true);
                setId(reservation._id);
              }}
            >
              <div className="user-res">
                {reservation?.customer_id
                  ? `${reservation.customer_id.firstname} ${reservation.customer_id.lastname}`
                  : "User Not Found"}
              </div>
              <div className="dated-res">
                {startDate.format("DD/MM/YYYY")} -{" "}
                {returnDate.format("DD/MM/YYYY")}
              </div>
            </div>
            <button
              className="cancel-button"
              onClick={() => {
                handleCanceld();
                setResDataForLoc(reservation);
                setId(reservation._id);
              }}
              aria-label="Cancel"
            >
              <span className="cancel-icon">✖</span>
            </button>
          </div>
          {viewType === "day" && (
            <div
              className="drag-handle-right ml-2"
              onMouseDown={(event) =>
                handleDrag(
                  "right",
                  event,
                  reservation,
                  reservationWidthPx,
                  reservationLeftPx,
                  startIdx,
                  endIdx
                )
              }
            >
              <MdDragIndicator />
            </div>
          )}
        </div>
      );
    };

    return vehicleDataLoc.map((car, rowIndex) => (
      <div key={rowIndex} className="grid-row">
        {viewType === "day" &&
          daysOfYear.map((day, cellIndex) =>
            renderGridCell(
              day,
              rowIndex,
              cellIndex,
              moment().isSame(day, "day")
            )
          )}

        {viewType === "week" &&
          weeksOfYear.map((week, index) =>
            renderGridCell(
              week.start,
              rowIndex,
              index,
              moment().isSame(week.start, "week")
            )
          )}

        {viewType === "month" &&
          monthsOfYear.map((month, index) =>
            renderGridCell(
              month.start,
              rowIndex,
              index,
              moment().isSame(month.start, "month")
            )
          )}

        {reservationDataLoc
          .filter((res) => res?.general_id?._id === car?._id)
          .map((reservation) => renderReservation(reservation, rowIndex))}
      </div>
    ));
  };

  const handleMouseDown = (e, rowId, cellId) => {
    if (!e.shiftKey) return; // Only proceed if Shift is pressed

    startCell.current = { rowId, cellId }; // Set the start cell
    isDragging.current = true;
    clearSelectedClasses();
    // Add the "selected" class to the clicked cell
    const cell = document.getElementById(`cell-${rowId}-${cellId}`);
    if (cell) cell.classList.add("selected");
  };

  const handleMouseMove = (e, rowId, cellId) => {
    if (!e.shiftKey) handleMouseUp();
    if (!isDragging.current || !startCell.current) return;

    clearSelectedClasses();
    // / Determine the range (start to current position)
    const [start, end] =
      startCell.current.cellId < cellId
        ? [startCell.current, { rowId, cellId }]
        : [{ rowId, cellId }, startCell.current];

    // Loop through the range and apply the "selected" class
    for (let i = start.cellId; i <= end.cellId; i++) {
      const cell = document.getElementById(`cell-${start.rowId}-${i}`);
      if (cell) cell.classList.add("selected");
    }
  };

  const handleMouseUp = () => {
    if (isDragging.current) {
      isDragging.current = false;
      // toast.info("Processing new reservation...");
      // Get the first and last selected cells based on the "selected" class
      const selectedCells = document.querySelectorAll(".grid-cell.selected");
      if (selectedCells.length > 0) {
        const firstSelectedCell = selectedCells[0];
        const lastSelectedCell = selectedCells[selectedCells.length - 1];

        // Get the data-id for the first and last selected cells
        const firstCellDataId = firstSelectedCell.getAttribute("data-id");
        const lastCellDataId = lastSelectedCell.getAttribute("data-id");

        setFinalNewResDates({
          ...finalNewResDates,
          start: firstCellDataId,
          end: lastCellDataId,
        });

        setTimeout(() => setShow(true));
      }
    }
  };

  const clearSelectedClasses = () => {
    // Loop through all cells and remove the "selected" class
    for (let rowId = 0; rowId < vehicleDataLoc.length; rowId++) {
      for (let cellId = 0; cellId < daysOfYear.length; cellId++) {
        const cell = document.getElementById(`cell-${rowId}-${cellId}`);
        if (cell) {
          cell.classList.remove("selected");
        }
      }
    }
  };

  const handleCanceld = () => {
    setIsModalOpen(true);
  };

  const updateCloseButtonPositions = () => {
    const reservations = document.querySelectorAll(".reservation-bar");

    if (reservations.length === 0) return;

    const containerRect = document
      .getElementById("calendar")
      .getBoundingClientRect();

    reservations.forEach((reservation) => {
      const reservationRect = reservation.getBoundingClientRect();
      const closeButton = reservation.querySelector(".cancel-button");
      const infoSection = reservation.querySelector(".reservation-info-data");

      // Calculate hidden portions
      const hiddenLeft = Math.max(0, containerRect.left - reservationRect.left);
      const hiddenRight = Math.max(
        0,
        reservationRect.right - containerRect.right
      );

      // Reposition the "X" button
      if (hiddenRight > 0) {
        // Move the button to the left side of the visible area
        closeButton.style.right = `${hiddenRight + 10}px`;
      } else if (hiddenLeft > 0) {
        // Move the button back to the default position
        closeButton.style.right = "10px";
      } else {
        // Keep the button in its original position if fully visible
        closeButton.style.right = "10px";
      }

      // Reposition the infoSection if hidden on the left
      if (hiddenLeft > 0) {
        // Check if the adjustment goes beyond the reservation width
        if (hiddenLeft < reservationRect.width) {
          infoSection.style.paddingLeft = `${hiddenLeft + 10}px`;
        } else {
          // Reset to default if it exceeds reservation width
          infoSection.style.paddingLeft = "10px";
        }
      } else {
        // Reset the position to its default
        infoSection.style.paddingLeft = "10px";
      }
    });
  };

  const handleCloseModal = () => {
    setResDataForLoc(null);
    setIsModalOpen(false);
  };

  const handleConfirmDelete = async () => {
    await deleteReservationById(id);
    reservationList((prevReservations) =>
      prevReservations.filter((reservation) => reservation.id !== id)
    );
    setReload(!reload);
    setIsModalOpen(false);
    // onHide();
    setId(null);
    // alert("Reservation deleted!"); // Replace this with your actual delete logic
  };

  useEffect(() => {
    const handleStorageChange = (event) => {
      let apptheme = localStorage.getItem("app-theme");

      if (apptheme === "system") {
        apptheme = window.matchMedia("(prefers-color-scheme: dark)").matches
          ? "dark"
          : "light";
      }
      setTheme(apptheme);
    };

    window.addEventListener("localStorageUpdate", handleStorageChange);

    return () => {
      window.removeEventListener("localStorageUpdate", handleStorageChange);
    };
  }, []);

  useEffect(() => {
    setTimeout(() => focusCurrent(), 1500);
  }, [viewType]); // Re-run when viewType changes

  useEffect(() => {
    dispatch(fetchVehicleList(appliedFilters)); // Fetch vehicle list from Redux
    dispatch(reservationList()); // Fetch reservation data from backend API
    setTimeout(() => focusCurrent(), 2500);
  }, [reload]);

  useEffect(() => {
    let finalVehicleD = vehicleList.filter((v) => {
      const typeMatch =
        appliedFilters.type.length > 0
          ? appliedFilters.type.includes(v.vi_type)
          : true;
      const categoryMatch =
        appliedFilters.category.length > 0
          ? appliedFilters.category.includes(v.vehicle_category)
          : true;

      const searchLower = searchTerm.trim().toLowerCase();
      const searchByText = searchLower
        ? `${v.manufacturer} ${v.model}`
            ?.toLowerCase()
            ?.includes(searchLower) ||
          v.manufacturer?.toLowerCase()?.includes(searchLower) ||
          v.model?.toLowerCase()?.includes(searchLower) ||
          v.license_plate?.toLowerCase()?.includes(searchLower) ||
          v?.declination?.[0]?.toLowerCase()?.includes(searchLower) ||
          v?.color?.name?.toLowerCase()?.includes(searchLower)
        : true;

      return typeMatch && categoryMatch && searchByText;
    });

    let finalReservationData = reservationData.filter((v) => {
      const statusMatch =
        appliedFilters.status.length > 0
          ? appliedFilters.status.includes(v.status)
          : true;
      const clientMatch =
        appliedFilters.client.length > 0
          ? appliedFilters.client.includes(v.client_type)
          : true;
      const companyMatch =
        appliedFilters.company.length > 0
          ? appliedFilters.company.includes(v?.company_id?.name)
          : true;
      return statusMatch && clientMatch && companyMatch;
    });

    setVehicleDataLoc(
      finalVehicleD.sort((a, b) =>
        `${a.manufacturer} ${a.model} ${a.declination[0]}`.localeCompare(
          `${b.manufacturer} ${b.model} ${a.declination[0]}`
        )
      )
    );
    setReservationDataLoc(finalReservationData);
  }, [vehicleList, reservationData, appliedFilters, searchTerm]);

  useEffect(() => {
    const handleScroll = (e) => {
      const scrollPosition = e.target.scrollTop;
      const sidebarDiv = document.getElementById("calendar");
      if (sidebarDiv) {
        sidebarDiv.scroll({ top: scrollPosition });
      }
    };
    const element = document.getElementById("sidebar-scroll");
    if (element) {
      element.addEventListener("scroll", handleScroll);
    }

    // Cleanup event listener on component unmount
    return () => {
      if (element) {
        element.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  useEffect(() => {
    const handleScroll = (e) => {
      if (!gridRef.current) return;

      // Determine the scroll position of the grid
      const scrollLeft = gridRef.current.scrollLeft;
      const scrollWidth = gridRef.current.scrollWidth;
      const clientWidth = gridRef.current.clientWidth;

      // Check if the user is near the left or right edge of the calendar grid
      const nearLeftEdge = scrollLeft < 100; // You can adjust the threshold (100) as needed
      const nearRightEdge = scrollLeft + clientWidth >= scrollWidth - 100;

      // If the user scrolls near the left or right edge, load more data
      if (nearLeftEdge) {
        appendChunks("left", viewType);
      } else if (nearRightEdge) {
        appendChunks("right", viewType);
      }

      updateCloseButtonPositions();
      const scrollPosition = gridRef.current.scrollTop;
      const sidebarDiv = document.getElementById("sidebar-scroll");
      if (sidebarDiv) {
        sidebarDiv.scroll({ top: scrollPosition });
      }
    };

    // Add scroll event listener to the grid
    const gridElement = gridRef.current;
    if (gridElement) {
      gridElement.addEventListener("scroll", handleScroll);
    }

    // Clean up the event listener on component unmount
    return () => {
      if (gridElement) {
        gridElement.removeEventListener("scroll", handleScroll);
      }
    };
  }, [appendChunks, viewType]);

  return (
    <div id="cal-con">
      <div style={{ position: "relative", display: "flex" }}>
        <div
          className="calendar-grid-container"
          style={{ position: "relative", left: "0", top: 0 }}
        >
          <div
            className="sidebar"
            style={{
              width: showFilters ? "498px" : "300px",
            }}
          >
            {showFilters && (
              <FilterSidebar
                setActiveFilter={setActiveFilter}
                activeFilter={activeFilter}
                setAppliedFilters={setAppliedFilters}
                resetFilters={resetFilters}
                appliedFilters={appliedFilters}
              />
            )}
            <div
              className="d-flex flex-column"
              style={{
                boxShadow: "0px 4px 8.6px 0px #0000001A",
                padding: "0 10px",
                marginLeft: showFilters ? "300px" : "5px",
                width: showFilters ? "195px" : "300px",
                height: "100vh",
                overflow: "auto",
              }}
              id="sidebar-scroll"
            >
              <ViewTypes
                viewType={viewType}
                setViewType={setViewType}
                searchTerm={searchTerm}
                toggleFilters={toggleFilters}
                handleSearchChange={handleSearchChange}
                showFilters={showFilters}
                focusCurrent={focusCurrent}
              />

              <CarsList
                vehicleDataLoc={vehicleDataLoc}
                handleCarImageClick={handleCarImageClick}
                showFilters={showFilters}
              />
            </div>
          </div>
        </div>
        <div className="calendar" id="calendar" ref={gridRef}>
          <div className="reservation-button-container">
            <button
              className="reservation-button"
              onClick={() => setShow(true)}
            >
              <FaCalendar className="calendar-icon" />
              <span>Nouvelle réservation</span>
            </button>
          </div>

          <div className="calendar-header" id="caledar-header-div">
            {overlays}
            {headers}
          </div>
          <div className="calendar-body">{getGridRows()}</div>

          {hoveredReservation && (
            <HoveredReservation
              theme={theme}
              hoveredReservation={hoveredReservation}
            />
          )}
        </div>
      </div>

      {isModalOpen && (
        <div className="modal-overlay-cls">
          <div className="modal-center-content-cls">
            <h2>Suppression de réservation</h2>
            <p>
              Vous êtes sur le point de supprimer la réservation de{" "}
              <b>
                {resDataForLoc?.customer_id?.firstname}{" "}
                {resDataForLoc?.customer_id?.lastname}
              </b>{" "}
              à la DATE du{" "}
              <b>{moment(resDataForLoc?.start_date).format("DD/MM/YYYY")}</b>
            </p>
            <div className="modal-actions">
              <button className="btn-primary" onClick={handleConfirmDelete}>
                Supprimer
              </button>
              <button className="btn-secondary" onClick={handleCloseModal}>
                Abandonner
              </button>
            </div>
          </div>
        </div>
      )}
      {show && (
        <AddReservation
          show={show}
          onHide={() => {
            setId("");
            setShow(false);
            setFinalNewResDates({ start: "", end: "", vehicle: null });
            clearSelectedClasses();
          }}
          res_id={id}
          setId={setId}
          name={"Nouvelle réservation"}
          theme={theme}
          finalNewResDates={finalNewResDates}
        />
      )}
    </div>
  );
};

export default CalendarNew;
