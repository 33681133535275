import { useEffect, useState } from "react";
import EditIcon from "../../assets/img/edit.svg";
import { ReactComponent as XIcon } from "../../assets/icons/x.svg";
import { IconButton, Input, InputBase } from "@mui/material";
import { IoCheckmarkSharp } from "react-icons/io5";
import { VscDiffAdded } from "react-icons/vsc";
import {
  updateLegalDocuments,
  updateLegalDocumentsName,
} from "../../services/Company/addCompanyApi";
import { HiOutlineLink } from "react-icons/hi2";
import { BsTrash } from "react-icons/bs";

function getFinalFileName(filePath) {
  if (!filePath) return "";
  const fileNameWithExtension = filePath.split("/").pop(); // Get the file name with extension
  return fileNameWithExtension.substring(
    0,
    fileNameWithExtension.lastIndexOf(".")
  ); // Remove the extension
}

function getFileExtension(filePath) {
  if (!filePath) return "";
  return filePath.split("/").pop().split(".").pop();
}

const CompanyDocuments = ({ formik, companyId }) => {
  const [selectedLanguage, setSelectedLanguage] = useState("En");
  const [editMode, setEditMode] = useState(!companyId ? true : false);
  const files = formik.values.compnayDetails.legal_documents;
  const selectedLanguageFiles = files?.[selectedLanguage.toUpperCase()] || {};
  const [fileNames, setFileNames] = useState({
    terms: "",
    sales: "",
  });

  useEffect(() => {
    const termsAndConditionsFileName = getFinalFileName(
      files?.[selectedLanguage.toUpperCase()]?.termsAndConditions
    );
    const saleAndUseFileName = getFinalFileName(
      files?.[selectedLanguage.toUpperCase()]?.saleAndUse
    );
    setFileNames({
      terms: termsAndConditionsFileName,
      sales: saleAndUseFileName,
    });
  }, [files, selectedLanguage, editMode]);

  const tncFileExt = getFileExtension(
    files?.[selectedLanguage.toUpperCase()]?.termsAndConditions
  );
  const salenUseFileExt = getFileExtension(
    files?.[selectedLanguage.toUpperCase()]?.saleAndUse
  );

  const handleFileChange = async (file, key) => {
    try {
      const formData = new FormData();
      formData.append("document", file);
      formData.append("language", selectedLanguage.toUpperCase());
      formData.append("key", key);

      const resp = await updateLegalDocuments(companyId, formData);
      formik.setFieldValue("compnayDetails", {
        ...formik.values.compnayDetails, // Access current values from formik.values
        legal_documents: {
          ...formik.values.compnayDetails.legal_documents, // Spread current legal_documents
          [selectedLanguage.toUpperCase()]: {
            ...(formik.values.compnayDetails.legal_documents?.[
              selectedLanguage.toUpperCase()
            ] || {}),
            [key]: resp.file, // Update the specific key with the new file
          },
        },
      });
    } catch {}
  };

  const removeLanguageKey = (language, key) => {
    const updatedLegalDocuments = {
      ...formik.values.compnayDetails.legal_documents,
      [language.toUpperCase()]: {
        ...formik.values.compnayDetails.legal_documents?.[
          language.toUpperCase()
        ],
      },
    };

    // Delete the specific key from the language object
    delete updatedLegalDocuments[language.toUpperCase()][key];

    // If the language object becomes empty, remove the language entirely
    if (
      Object.keys(updatedLegalDocuments[language.toUpperCase()]).length === 0
    ) {
      delete updatedLegalDocuments[language.toUpperCase()];
    }

    // Update the form field with the modified object
    formik.setFieldValue("compnayDetails", {
      ...formik.values.compnayDetails,
      legal_documents: updatedLegalDocuments,
    });
  };

  const fileNameUpdation = async () => {
    const payload = [
      {
        key: "termsAndConditions",
        filePath: files?.[selectedLanguage.toUpperCase()]?.termsAndConditions,
        newFileName: fileNames.terms,
      },
      {
        key: "saleAndUse",
        filePath: files?.[selectedLanguage.toUpperCase()]?.saleAndUse,
        newFileName: fileNames.sales,
      },
    ];

    const resp = await updateLegalDocumentsName(companyId, {
      filePaths: payload,
    });

    if (resp.status === true) {
      const tncFileNew = resp?.updatedFiles?.find(
        (r) => r.key === "termsAndConditions"
      );
      const saleFileNew = resp?.updatedFiles?.find(
        (r) => r.key === "saleAndUse"
      );

      formik.setFieldValue("compnayDetails", {
        ...formik.values.compnayDetails, // Access current values from formik.values
        legal_documents: {
          ...formik.values.compnayDetails.legal_documents, // Spread current legal_documents
          [selectedLanguage.toUpperCase()]: {
            ...(formik.values.compnayDetails.legal_documents?.[
              selectedLanguage.toUpperCase()
            ] || {}),
            // termsAndConditions: tncFileNew
            //   ? tncFileNew.newPath
            //   : formik.values.compnayDetails.legal_documents?.[
            //       selectedLanguage.toUpperCase()
            //     ].termsAndConditions,
            // saleAndUse: saleFileNew
            //   ? saleFileNew.newPath
            //   : formik.values.compnayDetails.legal_documents?.[
            //       selectedLanguage.toUpperCase()
            //     ].saleAndUse,
            ...(tncFileNew ? { termsAndConditions: tncFileNew.newPath } : {}),
            ...(saleFileNew ? { saleAndUse: saleFileNew.newPath } : {}),
          },
        },
      });

      setTimeout(() => {
        formik.handleSubmit();
        setEditMode(false);
      }, 2000);
    }
  };

  return (
    <section className="company-item-card" style={{ position: "relative" }}>
      <div className="d-flex policy-docs">
        <div style={{ width: "50%" }}>
          <div className="comp-item-header">
            <label role="button">
              Conditions générales de vente et d’utilisation
            </label>
          </div>

          {!selectedLanguageFiles?.saleAndUse && (
            <label style={{ fontWeight: "400" }}>
              <input
                type="file"
                style={{ display: "none" }} // Hides the input field
                onChange={(e) =>
                  handleFileChange(e.target.files[0], "saleAndUse")
                }
                disabled={editMode === false}
              />
              <div className="d-flex gap-1 add-file-com-btn mt-3">
                <VscDiffAdded color="#007AFF" fontSize="24px" />
                Ajouter un document
              </div>
            </label>
          )}
          {selectedLanguageFiles?.saleAndUse && (
            <div className="d-flex gap-1 add-file-com-btn mt-3 align-items-center">
              <HiOutlineLink color="#007AFF" fontSize="24px" />
              {!editMode ? (
                selectedLanguageFiles?.saleAndUse?.split("/").reverse()[0]
              ) : (
                <div className="d-flex align-items-center gap-1">
                  <input
                    className="form-control"
                    style={{ padding: "5px", width: "fit-content" }}
                    value={fileNames.sales}
                    onChange={(e) =>
                      setFileNames({
                        ...fileNames,
                        sales: e.target.value,
                      })
                    }
                  />
                  .{salenUseFileExt}
                </div>
              )}
              {editMode && (
                <BsTrash
                  color="#FF7477"
                  fontSize="22px"
                  onClick={() =>
                    removeLanguageKey(
                      selectedLanguage.toUpperCase(),
                      "saleAndUse"
                    )
                  }
                />
              )}
            </div>
          )}
        </div>
        <div style={{ width: "50%" }}>
          <div className="comp-item-header">
            <label role="button">Termes et conditions</label>
          </div>

          {!selectedLanguageFiles?.termsAndConditions && (
            <label style={{ fontWeight: "400" }}>
              <input
                type="file"
                style={{ display: "none" }} // Hides the input field
                onChange={(e) =>
                  handleFileChange(e.target.files[0], "termsAndConditions")
                }
                disabled={editMode === false}
              />
              <div className="d-flex gap-1 add-file-com-btn mt-3">
                <VscDiffAdded color="#007AFF" fontSize="24px" />
                Ajouter un document
              </div>
            </label>
          )}
          {selectedLanguageFiles?.termsAndConditions && (
            <div className="d-flex gap-1 align-items-center add-file-com-btn mt-3">
              <HiOutlineLink color="#007AFF" fontSize="24px" />
              {!editMode ? (
                selectedLanguageFiles?.termsAndConditions
                  ?.split("/")
                  .reverse()[0]
              ) : (
                <div className="d-flex align-items-center gap-1">
                  <input
                    className="form-control"
                    style={{ padding: "5px", width: "fit-content" }}
                    value={fileNames.terms}
                    onChange={(e) =>
                      setFileNames({
                        ...fileNames,
                        terms: e.target.value,
                      })
                    }
                  />
                  .{tncFileExt}
                </div>
              )}

              {editMode && (
                <BsTrash
                  color="#FF7477"
                  fontSize="22px"
                  onClick={() =>
                    removeLanguageKey(
                      selectedLanguage.toUpperCase(),
                      "termsAndConditions"
                    )
                  }
                />
              )}
            </div>
          )}
        </div>
      </div>

      <div
        style={{
          position: "absolute",
          right: "10px",
          top: "10px",
          display: "flex",
          gap: "10px",
          alignItems: "center",
        }}
      >
        <div className="d-flex gap-2">
          {["Fr", "En"].map((lang) => (
            <div
              className={`language-opts ${
                selectedLanguage === lang ? `active-lang` : ``
              } `}
              role="button"
              onClick={() => setSelectedLanguage(lang)}
            >
              <span>{lang}</span>
            </div>
          ))}
        </div>

        {!editMode && (
          <IconButton size="small" onClick={() => setEditMode(true)}>
            <img src={EditIcon} alt="Edit" width={24} height={24} />
          </IconButton>
        )}
        {editMode && (
          <IconButton
            size="small"
            onClick={() => setEditMode(false)}
            style={{
              height: "24px",
              width: "24px",
              borderRadius: "6px",
              border: "1px solid rgb(75 70 92 / 47%)",
            }}
          >
            <XIcon />
          </IconButton>
        )}
        {editMode && (
          <IconButton
            size="small"
            onClick={async () => {
              await fileNameUpdation();
            }}
            style={{
              height: "24px",
              width: "24px",
              borderRadius: "6px",
              border: "1px solid rgb(75 70 92 / 47%)",
            }}
          >
            <IoCheckmarkSharp />
          </IconButton>
        )}
      </div>
    </section>
  );
};

export default CompanyDocuments;
